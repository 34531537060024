import * as Axios from '@/service/AxiosService'
import config from '@/const/const.js'
const constant = config.constant
import Util from '@/mixins/util'

export default {
  namespaced: true,
  state: {
    // 登録済カード情報
    cardList: [],
    // API実行時に返却されたエラーコード
    apiErrorCode: '',
    // 優先使用されるクレジットカード連番
    priorityCardSeq: '',
    // 一時的な選択クレジットカード連番
    temporarySelectedCardSeq: '',
    //遷移元パス
    screenTransitionSource: {
      displayName: '',
    },
    // ユーザーの決済ステータス（決済の失敗があった場合はpaymentNG、失敗状態が解消した場合は空文字）
    paymentStatus: '',
  },
  getters: {
    /**
     * カード情報を表示形式に整形
     * 「YYMM」形式の有効期限を「MM/20YY」に変更し、cardSeqの昇順にソート
     * @param {Object} state Storeオブジェクト
     * @returns {Array} カード情報の配列
     */
    displayCardList(state) {
      const cardList = state.cardList.map((item) => {
        // eslint-disable-next-line no-magic-numbers
        const yy = item.expire.substr(0, 2)
        // eslint-disable-next-line no-magic-numbers
        const mm = item.expire.substr(-2)

        return {
          cardSeq: item.cardSeq,
          cardNumber: item.cardNumber,
          expire: `${mm}/20${yy}`,
        }
      })

      return cardList.sort((a, b) => Number(a.cardSeq) - Number(b.cardSeq))
    },
    /**
     * 下記の優先度に則り使用するカード情報を返却する
     *
     * ①カード選択をユーザーが切り替えた際の、選択したカード情報(一時カード情報)
     * ②過去手配した際のカード情報(優先対象のカード情報)
     * ③登録が一番古いカード情報(先頭のカード情報)
     * ※登録済カードが0件の場合は空オブジェクトを返却
     * @param {Object} state Storeオブジェクト
     * @param {Object} getters Storeオブジェクト
     * @returns {Object} カード情報のオブジェクト
     */
    selectedCard(state, getters) {
      const displayCardList = getters.displayCardList
      // 登録しているカードが一枚もない場合、空オブジェクトを返却する
      if (displayCardList.length === 0) return {} // eslint-disable-line no-magic-numbers

      // 一時的な選択カード連番がある場合、該当するカード情報を返却
      const temporarySelectedCardSeq = state.temporarySelectedCardSeq
      const temporarySelectedCardInfo = displayCardList.find((card) => {
        return card.cardSeq === temporarySelectedCardSeq
      })
      if (temporarySelectedCardInfo) return temporarySelectedCardInfo

      // 優先して表示したいカードが存在する場合、優先対象のカード情報を返却
      const priorityCardInfo = displayCardList.find((card) => {
        return card.cardSeq === state.priorityCardSeq
      })
      if (priorityCardInfo) return priorityCardInfo

      // 上記に当てはまらない場合、先頭のカード情報を返却
      return displayCardList[0] // eslint-disable-line no-magic-numbers
    },
  },
  mutations: {
    /**
     * カード情報更新処理
     * @param {Object} state Storeオブジェクト
     * @param {Array} cardList カード情報
     */
    updateCardList(state, cardList) {
      state.cardList = cardList
    },
    /**
     * ユーザーの決済ステータス更新処理
     * @param {Object} state Storeオブジェクト
     * @param {Array} paymentStatus ユーザーの決済ステータス
     */
    updatePaymentStatus(state, paymentStatus) {
      state.paymentStatus = paymentStatus
    },
    /**
     * API実行時のエラーコード更新処理
     * @param {Object} state Storeオブジェクト
     * @param {String} errorCode エラーコード
     */
    updateApiErrorCode(state, errorCode) {
      state.apiErrorCode = errorCode
    },
    /**
     * 優先使用されるカード連番更新処理
     * @param {Object} state Storeオブジェクト
     * @param {String} priorityCardSeq 優先使用されるクレジットカード連番
     */
    updatePriorityCardSeq(state, priorityCardSeq) {
      state.priorityCardSeq = priorityCardSeq
    },
    /**
     * 一時的なカード選択情報を保持
     * @param {*} state
     * @param {*} temporarySelectedCardSeq カード連番
     */
    updateTemporarySelectedCardSeq(state, temporarySelectedCardSeq) {
      state.temporarySelectedCardSeq = temporarySelectedCardSeq
    },
    /**
     * 一時的なカード選択情報を初期化
     * @param {*} state
     */
    clearTemporarySelectedCardSeq(state) {
      state.temporarySelectedCardSeq = ''
    },
    /**
     * 遷移元パス更新処理
     * @param {*} state Storeオブジェクト
     * @param {*} obj 遷移元パス
     */
    updateScreenTransitionSource(state, obj) {
      state.screenTransitionSource = {
        displayName: obj?.displayName || '',
      }
    },
  },
  actions: {
    /**
     * API失敗時(failed)の処理
     * @param {*} commit Storeオブジェクト
     * @param {Object} apiResponse APIレスポンス
     * @returns
     */
    apiFailedAction({commit}, apiResponse) {
      // APIから返却されたエラーコード
      const apiErrorCode = apiResponse.body?.param?.appErrCode
      // クレジットカードエラーモーダル表示対象判定
      const isShowCreditCardError = Object.values(
        constant.API_ERROR.CREDIT_CARD_PAYMENT
      ).some((error) => {
        return error.code === apiErrorCode
      })

      if (apiErrorCode && isShowCreditCardError) {
        // クレジットカードエラーモーダル表示対象の場合は、storeのエラーコード情報を更新
        commit('updateApiErrorCode', apiErrorCode)
        commit('endLoading', null, {root: true})
        return
      }
      // 対象外の場合は共通エラーとしてハンドリング
      throw new Error()
    },
    /**
     * クレジットカード登録API実行処理
     * @param {*} commit Storeオブジェクト
     * @param {String} gmoToken トークン文字列
     * @param {Function} success 成功時に実行するコールバック関数
     * @param {Function} failed 失敗時に実行するコールバック関数
     * @param {Function} error エラー時に実行するコールバック関数
     */
    registerCreditCard({commit}, {gmoToken, success, failed, error}) {
      const body = {
        token: gmoToken,
      }
      return Axios.post('creditCard', body, {needsAccessToken: true})
        .then((data) => {
          if (data.statusCode === constant.API_STATUS_CODE_OK) {
            // 登録カード一覧を更新
            commit('updateCardList', data.body.cardList)
            if (success) {
              success()
            }
          } else {
            throw data
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            throw e
          } else {
            if (failed) {
              failed(e)
              return
            }
            throw new Error()
          }
        })
    },
    /**
     * クレジットカード情報を削除する
     * @param {Object} commit Storeオブジェクト
     * @param {String} cardSeq 削除するカード連番
     * @param {Function} success 成功時に実行するコールバック関数
     * @param {Function} failed 失敗時に実行するコールバック関数
     * @param {Function} error エラー時に実行するコールバック関数
     */
    deleteCreditCard({commit}, {cardSeq, success, failed, error}) {
      return Axios.del('creditCard', {cardSeq}, {needsAccessToken: true})
        .then((data) => {
          if (data.statusCode === constant.API_STATUS_CODE_OK) {
            commit('updateCardList', data.body.cardList)
            if (success) {
              success()
            }
          } else {
            throw data
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * ローカルストレージとstateの優先使用されるクレジットカード連番を更新する
     * @param {Object} commit Storeオブジェクト
     * @param {String} priorityCardSeq 優先使用されるクレジットカード連番
     */
    updateLocalPriorityCardSeq({commit}, priorityCardSeq) {
      // ローカルストレージへ保存
      Util.methods.setLocalStorage('PriorityCardSeq', priorityCardSeq)
      commit('updatePriorityCardSeq', priorityCardSeq)
    },
    /**
     * ローカルストレージから優先使用されるクレジットカード連番を取得し、state更新
     * @param {Object} commit Storeオブジェクト
     */
    loadLocalPriorityCardSeq({commit}) {
      // ローカルストレージから取得
      const priorityCardSeq = Util.methods.getLocalStorage('PriorityCardSeq')
      commit('updatePriorityCardSeq', priorityCardSeq || '')
    },
    /**
     * 決済取引登録実行
     * 本人認証を行う為のリダイレクト先URLを受け取る
     * @param {Function} success 成功時に実行するコールバック関数
     * @param {Function} failed 失敗時に実行するコールバック関数
     * @param {Function} error エラー時に実行するコールバック関数
     * @param {Number} amount エラー時に実行するコールバック関数
     * @param {String} cardSeq クレカ連番
     * @returns Promiseオブジェクト
     */
    registerPaymentTransaction(_, {success, failed, error, amount, cardSeq}) {
      const body = {amount, cardSeq}
      return Axios.post('paymentTransaction', body, {needsAccessToken: true})
        .then((res) => {
          if (res.statusCode == constant.API_STATUS_CODE_OK) {
            if (success) {
              success(res.body)
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
  },
}
