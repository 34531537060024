<template>
  <div class="h-full overflow-y-auto">
    <!-- ヘッダー -->
    <Header
      headerTitle="お支払い方法"
      :isShowPrevious="true"
      @clickPrevious="goToBackPage()"
    />
    <!-- 支払い方法リスト -->
    <div class="bg-white contents-height">
      <PaymentMethodList
        :cardList="cardList"
        :isShowDeleteIcon="true"
        @click-payment-method-delete="onClickPaymentMethodDelete($event)"
        @click-register-payment-method="onClickRegisterPaymentMethod()"
      />
    </div>

    <!-- 削除確認ポップアップ -->
    <Modal
      v-if="isShowDeleteConfirm"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="center px-5 pt-9 pb-6">
        <div class="text-W5 text-[17px]">
          登録されたお支払い情報を削除します。よろしいですか？
        </div>
        <!-- ボタン -->
        <div class="flex justify-center mt-5 items-center">
          <div
            class="w-1/2 py-4 the-button-danger text-W7 text-[15px] leading-[15px] mr-3"
            @click="deleteSelectedCard()"
          >
            削除
          </div>
          <div class="w-1/2">
            <TheButton
              text="もどる"
              @click-button="isShowDeleteConfirm = false"
            />
          </div>
        </div>
      </div>
    </Modal>

    <!-- 削除完了ポップアップ -->
    <Modal
      v-if="isShowDeleteComplete"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="center px-5 pt-9 pb-6">
        <div class="text-W5 text-[17px]">お支払い情報を削除しました。</div>
        <!-- ボタン -->
        <div class="flex justify-center mt-3 items-center">
          <TheButton text="OK" @click-button="isShowDeleteComplete = false" />
        </div>
      </div>
    </Modal>

    <!-- クレジットカード関連エラーポップアップ -->
    <CreditCardErrorModal />
  </div>
</template>

<script>
import Header from '@/components/organisms/Header.vue'
import PaymentMethodList from '@/components/molecules/PaymentMethodList.vue'
import Modal from '@/components/Modal.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import CreditCardErrorModal from '@/modals/CreditCardErrorModal.vue'

const PaymentMethodManagement = {
  name: 'PaymentMethodManagement',
  components: {
    Header,
    PaymentMethodList,
    Modal,
    TheButton,
    CreditCardErrorModal,
  },
  data() {
    return {
      // クレジットカード削除確認ポップアップ表示フラグ
      isShowDeleteConfirm: false,
      // クレジットカード削除完了ポップアップ表示フラグ
      isShowDeleteComplete: false,
      // 選択中のクレジットカード連番
      selectedCardSeq: '',
    }
  },
  computed: {
    /**
     * 表示形式に整形されたカード情報の配列を取得
     */
    cardList() {
      return this.$store.getters['CreditCardsStore/displayCardList']
    },
  },
  methods: {
    /**
     * 支払い方法削除ボタンクリック時の処理
     * @param {String} selectedCardSeq 選択されたカード連番
     */
    onClickPaymentMethodDelete(selectedCardSeq) {
      this.selectedCardSeq = selectedCardSeq
      this.isShowDeleteConfirm = true
    },
    /**
     * 選択中のカードを削除する
     */
    deleteSelectedCard() {
      this.$store.commit('startLoading')
      this.isShowDeleteConfirm = false

      const success = () => {
        this.isShowDeleteComplete = true
        this.$store.commit('endLoading')
      }
      const failed = (apiResponse) => {
        // クレジットカードエラーモーダル表示判定処理を実行
        this.$store.dispatch('CreditCardsStore/apiFailedAction', apiResponse)
      }

      // 削除処理
      this.$store.dispatch('CreditCardsStore/deleteCreditCard', {
        cardSeq: this.selectedCardSeq,
        success,
        failed,
      })
    },
    /**
     * 前のページに遷移する
     */
    goToBackPage() {
      this.$router.push({name: this.$config.DISPLAY_MENU_TOP})
    },
    /**
     * お支払い情報登録画面に遷移する
     */
    onClickRegisterPaymentMethod() {
      this.$router.push({name: this.$config.DISPLAY_REGISTER_CREDIT_CARD})
    },
  },
}
export default PaymentMethodManagement
</script>

<style scoped>
.contents-height {
  height: calc(100% - 48px);
}
</style>
