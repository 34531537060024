<template>
  <div class="flex items-center">
    <div v-if="isBackButton" class="mr-4">
      <img
        src="@/assets/common/Icon_Left_black.svg"
        class="h-4"
        @click="$emit('click-back-button')"
      />
    </div>
    <div class="flex-grow relative input-area">
      <input
        :id="id"
        ref="textFormInput"
        class="text-w4 input-text bg-white border-[0.5px] border-solid border-gray400 scroll-my-7"
        :class="inputClass"
        :type="type"
        :inputmode="inputmode"
        :placeholder="placeholder"
        :value="editingText"
        v-model.trim="editingText"
        :maxlength="maxlength"
        :disabled="isDisabled"
        :name="name"
        :autocomplete="autocomplete"
        @input="$emit('input-text', editingText)"
        @focus="onFocus"
        @blur="onBlur"
        @keypress.enter="$emit('enter', editingText)"
      />
    </div>
  </div>
</template>

<script>
const TextForm = {
  name: 'TextForm',
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },    
    // input要素に入力できる最大文字数
    maxlength: {
      type: String,
      default: '-1',
    },
    // input要素に表示するテキスト
    text: String,
    // input要素のプレースホルダー
    placeholder: {
      type: String,
      default: '入力',
    },
    // input要素左の「<」ボタン表示有無
    isBackButton: {
      type: Boolean,
      default: true,
    },
    // input要素で表示するコントロールの型
    type: {
      type: String,
      default: 'search',
    },
    // input要素で表示するキーボードの指定
    inputmode: {
      type: String,
      default: 'text',
    },
    // input要素に指定するクラス
    inputClass: {
      type: String,
      default: '',
    },
    // inputタグの操作可否状態
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click-back-button', 'input-text', 'enter', 'focus', 'blur'],
  data() {
    return {
      editingText: this.text, // 入力中のテキスト
      hasFocus: false, // フォーカスを持っているかフラグ
    }
  },
  watch: {
    text(newText) {
      this.editingText = newText
      this.$emit('input-text', this.editingText)
    },
  },
  mounted() {
    window.addEventListener('resize', this.onWindowResize)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onWindowResize)
  },
  methods: {
    /**
     * Inputタグにフォーカスを当てる
     */
    focusInputForm() {
      this.$refs.textFormInput.focus()
    },
    /**
     * Inputタグのフォーカスを外す
     * @param {Number} waitTime 処理待ち時間(ミリ秒)
     */
    async blurInputForm(waitTime) {
      this.$refs.textFormInput.blur()

      const zero = 0
      if (Number.isInteger(waitTime) && zero < waitTime) {
        // 整数の指定があった場合、処理を待機させる
        await this.wait(waitTime)
      }
    },
    /**
     * 処理待ち
     * @param {Number} waitTime 処理待ち時間(ミリ秒)
     * @returns {Promise} 指定時間経過後resolveされるPromiseオブジェクト
     */
    wait(waitTime) {
      return new Promise((resolve) => {
        setTimeout(resolve, waitTime)
      })
    },
    /**
     * リセット
     */
    reset() {
      this.editingText = ''
    },
    /**
     * フォーカスが当たった時に発火されるイベント
     */
    onFocus() {
      // テキストフィールドがスクロール外にある場合、表示させる
      this.$refs.textFormInput.scrollIntoView({block: 'nearest'})
      this.hasFocus = true
      this.$emit('focus')
    },
    /**
     * フォーカスが外れた時に発火されるイベント
     */
    onBlur() {
      this.hasFocus = false
      this.$emit('blur')
    },
    /**
     * Windowリサイズ時に発火されるイベント
     */
    onWindowResize() {
      // ソフトウェアキーボードがアニメーションにより徐々に表示される場合、キーボードにより入力欄が隠れてしまう場合があるため対策
      if (this.hasFocus) {
        this.$refs.textFormInput.scrollIntoView({block: 'nearest'})
      }
    },
  },
}
export default TextForm
</script>

<style scoped>
.input-text {
  height: 43px;
  border-radius: 8px;
  outline: none;
  line-height: 15px;
  font-size: 15px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  color: #1a1c21;
  font-weight: 400;
  caret-color: #0072ff;
  transition: padding-right 0.01s;
}
.input-text::placeholder {
  color: #979ca1;
  font-weight: 300;
}
.input-area > .input-text:focus {
  border-color: #0072ff;
  padding-right: 40px;
}

input:disabled {
  opacity: 0.4;
}

/*  input type="search" 共通設定  */
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none; /* デフォルトのサーチキャンセルボタンを非表示 */
}
input[type='search']:focus::-webkit-search-cancel-button {
  background-image: url(@/assets/common/Icon_Text_Form_Close_Black.svg);
  background-repeat: no-repeat;
  background-size: 26px 15px; /* キャンセルボタンのサイズ */
  background-position-y: center;
  background-position-x: -2px;
  height: 43px;
  width: 36px;
  padding: 0;
  position: absolute;
  right: 0;
}
</style>
