const Ajv = require('ajv')
const AddFormats = require('ajv-formats')
const ajv = new Ajv()

// ビルトインフォーマット読み込み
AddFormats(ajv)

// バリデーション設定
const telValidate = ajv.compile({type: 'string', pattern: '^([0-9]{10,11})$'})
const emailValidate = ajv.compile({type: 'string', format: 'email'})
const claimReceiptNumberValidate = ajv.compile({
  type: 'string',
  pattern: '^([0-9]{13})$',
})
const creditCardHolderValidate = ajv.compile({
  type: 'string',
  pattern: '^[A-Z ]*$',
})
const creditCardNumberValidate = ajv.compile({
  type: 'string',
  pattern: '^([0-9]{14,16})$',
})
const creditCardExpireMonthValidate = ajv.compile({
  type: 'string',
  pattern: '^(^(1[0-2])$|^(0[1-9])$)',
})
const creditCardExpireYearValidate = ajv.compile({
  type: 'string',
  pattern: '^([0-9]{2})$',
})
const creditCardSecurityCodeValidate = ajv.compile({
  type: 'string',
  pattern: '^([0-9]{3,4})$',
})

export default {
  methods: {
    /**
     * 電話番号のバリデーションを行い、結果を返す
     * @param {*} value 値
     * @returns バリデーション結果
     */
    tel(value) {
      const result = telValidate(value)
      return result
    },
    /**
     * メールアドレスのバリデーションを行い、結果を返す
     * @param {*} value 値
     * @returns バリデーション結果
     */
    email(value) {
      const result = emailValidate(value)
      return result
    },
    /**
     * 受付番号のバリデーションを行い、結果を返す
     * @param {*} value 値
     * @returns バリデーション結果
     */
    claimReceiptNumber(value) {
      const result = claimReceiptNumberValidate(value)
      return result
    },
    /**
     * カード名義人のバリデーションを行い、結果を返す
     * @param {*} value 値
     * @returns バリデーション結果
     */
    creditCardHolder(value) {
      return creditCardHolderValidate(value)
    },
    /**
     * クレジットカード番号のバリデーションを行い、結果を返す
     * @param {*} value 値
     * @returns バリデーション結果
     */
    creditCardNumber(value) {
      return creditCardNumberValidate(value)
    },
    /**
     * クレジットカード有効期限(月)のバリデーションを行い、結果を返す
     * @param {*} value 値
     * @returns バリデーション結果
     */
    creditCardExpireMonth(value) {
      return creditCardExpireMonthValidate(value)
    },
    /**
     * クレジットカード有効期限(年)のバリデーションを行い、結果を返す
     * @param {*} value 値
     * @returns バリデーション結果
     */
    creditCardExpireYear(value) {
      return creditCardExpireYearValidate(value)
    },
    /**
     * クレジットカードセキュリティコードのバリデーションを行い、結果を返す
     * @param {*} value 値
     * @returns バリデーション結果
     */
    creditCardSecurityCode(value) {
      return creditCardSecurityCodeValidate(value)
    },
  },
}
