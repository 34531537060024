<template>
  <div class="overflow-hidden" ref="panelContents">
    <Teleport to="body">
      <transition name="slide" v-on:after-enter="automaticGrantFocus()">
        <BasePanel
          v-if="isShowSuggest"
          :spaceHeight="panelSpaceHeight"
          :isOverflowYAuto="true"
          :isRoundedTop="false"
          :isShowShadow="false"
          ref="basePanel"
        >
          <template v-slot:body>
            <div class="h-full flex flex-col">
              <!--
            TODO:
              TextForm コンポーネントを変更すると他viewに影響が出るため、一旦 TextFormV2コンポーネントを新規作成
              デザイン改修終わり次第V2をとり、TextForm コンポーネントに戻す
          -->
              <TextFormV2
                id="textFormInput"
                class="searchText mx-5 pb-3"
                inputClass="bg-gray200 !border-[0px]"
                :placeholder="getPlaceHolderText"
                @input-text="onchangeText($event)"
                @click-back-button="$emit('click-back-button')"
                @focus="changeInputFlg(true)"
                @blur="changeInputFlg(false)"
                :maxlength="'100'"
                ref="textForm"
              />
              <div
                v-if="isEditing"
                class="overflow-y-auto scroll-bar-none pb-10 flex-1"
              >
                <AddressSuggestList
                  class="address-suggest-list"
                  :address="word"
                  @click-address-suggest="clickAddressSuggestList($event)"
                />
                <SuggestList
                  :spots="spots"
                  @click-suggest="completeForm($event)"
                  class="suggest-list"
                />
              </div>
              <template v-else>
                <div>
                  <!-- お気に入り地点 -->
                  <SuggestFavoriteSpots
                    @select-favorite-spot="completeForm($event)"
                  />
                </div>
                <div class="pt-3.5 flex">
                  <div class="border-b-[1px] border-gray300 w-5" />
                  <BaseTab
                    :tabs="Object.values(historyTab)"
                    v-model="selectedTab"
                    class="flex-1"
                  />
                  <div class="border-b-[1px] border-gray300 w-5" />
                </div>
                <keep-alive>
                  <SpotHistoryList
                    v-if="selectedTab === historyTab.spot.id"
                    :spotHistoryList="spotHistoryList"
                    @click-spot-history="completeForm($event)"
                    class="overflow-y-auto scroll-bar-none flex-1 pb-10 pt-4 history-list"
                  />
                  <RouteHistoryCardList
                    v-else-if="selectedTab === historyTab.route.id"
                    class="overflow-y-auto scroll-bar-none flex-1 pb-10 pt-4 px-5"
                    :routeHistoryList="routeHistoryList"
                    @click-route-history="emitHistoryRoute($event)"
                  />
                </keep-alive>
              </template>
            </div>
          </template>
        </BasePanel>
      </transition>
      <!-- 住所取得エラーポップアップ -->
      <Modal
        v-if="isNoAddressPopupFlg"
        class="modal text-center"
        :isShowCloseButton="false"
        :isModalCenter="true"
        modalPaddingX="20px"
      >
        <div class="px-4 pt-9 pb-4">
          <div class="pb-5 text-W5 text-[17px] leading-[17px]">
            選択した住所が存在しません。
          </div>
          <div
            class="text-W7 the-button-default text-[15px] leading-[15px] py-4 mx-auto"
            @click="isNoAddressPopupFlg = false"
          >
            OK
          </div>
        </div>
      </Modal>
    </Teleport>
  </div>
</template>
<script>
import TextFormV2 from '@/components/TextFormV2.vue'
import SuggestList from '@/components/SuggestList.vue'
import SpotHistoryList from '@/components/organisms/searchRoute/SpotHistoryList.vue'
import AddressSuggestList from '@/components/AddressSuggestList.vue'
import deepcopy from 'deepcopy'
import Modal from '@/components/Modal.vue'
import BasePanel from '@/components/atoms/BasePanel.vue'
import util from '@/mixins/util'
import searchRouteUtil from '@/mixins/searchRouteUtil'
import SuggestFavoriteSpots from '@/components/molecules/searchRoute/SuggestFavoriteSpots.vue'
import RouteHistoryCardList from '@/components/organisms/searchRoute/RouteHistoryCardList.vue'
import BaseTab from '@/components/atoms/BaseTab.vue'
import {getRouteHistoryFromLocalStorage} from '@/utils/localStorageUtil'

const LOAD_SPOT_API_SEC = 100

// 検索履歴・ルート履歴のタブ定義
const HISTORY_TAB = {
  spot: {id: 1, title: '入力履歴'},
  route: {id: 2, title: 'ルート履歴'},
}
const SearchOptimalSuggest = {
  name: 'SearchOptimalSuggest',
  components: {
    TextFormV2,
    SuggestList,
    SpotHistoryList,
    AddressSuggestList,
    Modal,
    BasePanel,
    SuggestFavoriteSpots,
    RouteHistoryCardList,
    BaseTab,
  },
  mixins: [util, searchRouteUtil],
  emits: ['click-back-button', 'select-spot'],
  props: {
    // 地点検索表示フラグ
    isShowSuggest: {
      type: Boolean,
      default: false,
    },
    // 対象のスポット（出発地、経由地、目的地)
    targetSpot: {
      type: String,
    },
    // パネルの昇降位置調整
    panelSpaceHeight: {
      type: Number,
      require: false,
      default: 44,
    },
  },
  data() {
    return {
      word: '', // 入力テキスト
      spots: [], // スポット検索候補一覧
      timer: 0, // タイマーの識別情報
      isEditing: false, // true:入力中、false:未入力
      spotHistoryList: this.getSpotHistoryList(), // 検索履歴一覧
      routeHistoryList: getRouteHistoryFromLocalStorage(), // ストレージ内のルート履歴一覧
      selectedTab: HISTORY_TAB.spot.id, // 選択中の履歴タブ
      isNoAddressPopupFlg: false, // 住所検索エラーポップアップフラグ
    }
  },
  beforeUnmount() {
    // 入力中フラグを落とす
    this.changeInputFlg(false)
    // イベントリスナーを解除する（セーフ処理）
    window.removeEventListener('touchstart', this.onTouchStart)
    window.removeEventListener('touchend', this.onTouchEnd)
  },
  watch: {
    /**
     * 地点検索画面表示状態の監視
     */
    isShowSuggest: {
      immediate: true,
      handler(value) {
        // 表示に切り替わった時に、値を初期化し、スクロール領域の再計算を行う
        this.$nextTick(() => {
          if (value) {
            this.$refs.textForm.reset()
            this.word = ''
            this.isEditing = false
            this.spots = []
          }
        })
        // 最新のルート履歴に更新する
        this.fetchRouteHistoryList()
      },
    },
  },
  computed: {
    /**
     * 検索フォームに表示するテキストを取得
     */
    getPlaceHolderText() {
      let text
      switch (this.targetSpot) {
        case this.$config.SPOT_TYPE_START:
          text = '出発地を入力'
          break
        case this.$config.SPOT_TYPE_VIA:
          text = '経由地を入力'
          break
        case this.$config.SPOT_TYPE_GOAL:
          text = '行きたい場所を入力'
          break
        default:
          text = '住所を検索'
      }
      return text
    },
    /**
     * 検索/ルート履歴タブ情報
     */
    historyTab() {
      return HISTORY_TAB
    },
  },
  methods: {
    /**
     * 最新のルート履歴をローカルストレージから取得し反映する
     */
    fetchRouteHistoryList() {
      this.routeHistoryList = getRouteHistoryFromLocalStorage()
    },
    /**
     * 初回展開時のオートフォーカス付与
     */
    automaticGrantFocus() {
      this.$refs.textForm.focusInputForm()
    },
    /**
     * 入力中フラグを切り替える
     * @param {boolean} isInput 入力中かどうか
     */
    changeInputFlg(isInput) {
      this.$store.commit('updateInputFlag', isInput)
      // 入力時にタッチイベントリスナーを設定する
      // 解除はタッチ終了イベントで行う
      if (isInput) {
        window.addEventListener('touchstart', this.onTouchStart)
        window.addEventListener('touchend', this.onTouchEnd)
      }
    },
    /**
     * 入力文字の内容を用いて地点検索を行う
     * @param {String} value 検索文字
     */
    onchangeText(value) {
      this.word = value
      clearTimeout(this.timer)

      //入力中かの判定
      this.isEditing = value != ''
      if (!this.isEditing) {
        // 入力文字を削除して空文字（未入力状態）にした場合、スポット検索しない
        return
      }
      const vm = this
      this.spots = []
      const success = (spots) => {
        vm.spots = spots
      }
      this.timer = setTimeout(() => {
        vm.$store.dispatch('getSpot', {
          success: success,
          word: vm.word,
        })
      }, LOAD_SPOT_API_SEC)
    },
    /**
     * 住所検索
     * @param value 住所検索キーワード
     */
    clickAddressSuggestList(value) {
      if (this.isNull(value)) {
        // 空文字（未入力状態）の場合、住所検索しない
        return
      }

      this.$store.commit('startLoading')

      const vm = this
      const success = (addressInfo) => {
        vm.$store.commit('endLoading')
        // 検索結果が0件の場合はエラー表示
        if (addressInfo == null) {
          // 住所が見つからなければエラー表示
          vm.isNoAddressPopupFlg = true
          return
        }
        const searchAddressSpot = {
          name: addressInfo.name,
          address: addressInfo.name,
          coord: `${addressInfo.coord.lat},${addressInfo.coord.lon}`,
        }
        vm.completeForm(searchAddressSpot)
      }

      // 入力フォームにフォーカスが当たっていれば外す
      this.$refs.textForm.blurInputForm()

      //住所検索を実行
      this.$store.dispatch('getAddress', {
        success: success,
        word: value,
      })
    },
    /**
     * ストレージに履歴一覧を反映、検索条件に地点を反映を行い、検索Top画面に遷移
     * @param {Object} value 選択した地点情報
     */
    completeForm(value) {
      // ローカルストレージに格納
      this.spotHistoryList = this.setSpotHistoryList(value)
      let updateValue = deepcopy(value)

      if (value.buildingName != null && value.buildingName != '') {
        updateValue.address = updateValue.address.concat(
          ' ',
          updateValue.buildingName
        )
      }
      this.$emit('select-spot', updateValue)
    },
    /**
     * 選択されたルート履歴情報を連携する
     * @param {Object} historyRouteInfo 選択したルート
     */
    emitHistoryRoute(historyRouteInfo) {
      this.$emit('select-history-route', historyRouteInfo)
    },
    /**
     * タッチ開始時処理
     * @param {Object} event イベント
     */
    onTouchStart(event) {
      // アクティブなエレメントのフォーカスを解除する
      // ターゲットがテキストフォームの場合は解除しない
      let obj = document.activeElement
      if (obj && event.target != document.getElementById('textFormInput')) {
        obj.blur()
      }
    },
    /**
     * タッチ終了時処理
     * @param {Object} event イベント
     */
    onTouchEnd(event) {
      // タッチのターゲットがテキストフォームの場合は処理しない
      if (event.target == document.getElementById('textFormInput')) {
        return
      }
      // リスナーを解除する
      window.removeEventListener('touchstart', this.onTouchStart)
      window.removeEventListener('touchend', this.onTouchEnd)
    },
  },
}
export default SearchOptimalSuggest
</script>
<style scoped>
/* パネル昇降のトランジション */
.slide-enter-active,
.slide-leave-active {
  transform: translate(0px, 0px) translateZ(1px);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(100vh) translateY(0px) translateZ(1px);
}

.img {
  box-shadow: 0 0 14px 0 rgba(2, 14, 34, 0.27);
  background-color: #f8fafc;
}
.buttonBox {
  margin-left: 37.5px;
  margin-right: 37.5px;
}
.modal-box {
  padding: 48px 38px 32px 38px;
}
.bmaps-button {
  display: flex;
  justify-content: center;
}
.bmaps-button > img {
  max-height: 32px;
}
.overlay-spot-name {
  /* カードの左右マージン 32px × 2 = 64px*/
  /* カード内の左マージン 24px */
  /* カード内の右マージン 16px */
  /* 画像と画像右側のマージン 48px */
  width: calc(100vw - 152px);
}

.history-list :deep(.history-col),
.address-suggest-list,
.suggest-list :deep(.suggest-col) {
  padding-right: 20px;
  padding-left: 20px;
}
</style>
