<template>
  <div id="BicycleReservationPaymentConfirm" class="fixed-app flex flex-col">
    <!-- ヘッダー -->
    <Header
      ref="arrangementHeader"
      headerTitle="お支払い"
      :isShowPrevious="true"
      :isShowClose="true"
      @clickPrevious="goToBackPage()"
      @clickClose="clickClose(true)"
    />
    <!-- ボディ -->
    <div class="overflow-y-auto scroll-bar-none px-5 pb-6 flex-1">
      <!-- タイトル -->
      <div
        class="pt-5 text-W4 text-[15px] leading-[23px] text-left overflow-hidden"
      >
        以下のお支払い方法で決済します。
        <div class="text-[14px] leading-[22px] ml-1">
          <div class="flex flex-wrap mt-2">
            ・
            <div class="flex-1 pl-2">
              一時お預かり金は料金確定時またはキャンセル時に返却されます。
            </div>
          </div>
          <div class="flex flex-wrap">
            ・
            <div class="flex-1 pl-2">
              サービス利用時に正式な金額に変更されます。
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="text-W7 text-[13px] leading-none text-left mb-2">
          一時お預かり金
        </div>

        <div class="rounded-[8px] border border-gray300 border-solid p-4">
          <div class="flex items-center">
            <div class="mr-4 w-11 h-10">
              <img
                class="w-11 h-10"
                src="@/assets/arrangementIcon/bicycle/IconArrangementBikeshare.svg"
              />
            </div>
            <div class="text-W7 text-[18px] text-left">
              バイクシェアサービス
            </div>
          </div>
          <div class="text-W7 text-[22px] mt-6 text-right">￥330</div>
        </div>
      </div>

      <!-- ご利用料金 -->
      <div class="mt-4">
        <div class="text-W7 text-[13px] leading-none text-left mb-2">
          ご利用料金
        </div>
        <div
          class="rounded-[8px] border border-gray300 border-solid text-W4 text-[15px] leading-none text-left p-4"
        >
          {{ formattedPrice }}
        </div>
      </div>

      <!-- 支払い方法カード -->
      <div class="mt-4">
        <div class="text-W7 text-[13px] leading-none text-left mb-2">
          お支払い方法
        </div>

        <SelectedPaymentCard
          :priorityCard="priorityCard"
          :hasPriorityCard="hasPriorityCard"
          @change-credit-card="goToCreditCardSelect()"
          @register-credit-card="goToRegisterCreditCard()"
        />
      </div>

      <!-- 電話番号 -->
      <div class="text-left mt-6">
        <div class="text-W7 text-[13px] leading-[13px] mb-2">電話番号</div>
        <TextFormWithValidation
          inputClass="bg-gray200 border-gray200"
          v-model:isError="isTelError"
          :text="tel"
          :validationType="['required', 'tel']"
          maxlength="100"
          placeholder="07000123456"
          errorMessage="正しい電話番号を入力してください。"
          @input-text="tel = $event"
        />
      </div>

      <!-- メールアドレス -->
      <div class="mt-4 text-left">
        <div class="text-W7 text-[13px] leading-[13px] mb-2">
          メールアドレス
          <span class="text-W4 text-[11px] leading-[11px text-gray">
            （編集不可）
          </span>
        </div>
        <TextForm
          inputClass="bg-gray200 border-gray200"
          :text="email"
          :isDisabled="true"
          :isBackButton="false"
        />
      </div>

      <!-- 同意事項 -->
      <div class="mt-4">
        <div class="text-W7 text-[13px] leading-none text-left mb-2">
          同意事項
        </div>
        <div class="rounded-[8px] border border-gray300 border-solid text-left">
          <div class="p-4">
            ご利用にあたって以下にご同意いただくものとします。
            <div class="mt-4 text-[0]">
              <div
                class="base-link-text text-W5 text-primary underline text-[13px] inline-block leading-[13px]"
                @click="isShowBicyclePolicy = true"
              >
                ドコモ・バイクシェア 利用規約
              </div>
            </div>
            <div class="mt-4 text-[0]">
              <BaseLink
                label="ドコモ・バイクシェア プライバシーポリシー"
                class="leading-[13px]"
                :url="docomoBikeSharePolicyUrl"
              />
            </div>
          </div>
          <div class="p-4 border-t border-gray300 border-solid">
            <BaseCheckBox v-model="isApplyPolicy" label="上記に同意します。" />
          </div>
        </div>
      </div>

      <div class="mt-6 text-left text-[0]">
        <BaseLink
          label="特定商取引法に基づく表記"
          class="leading-[13px]"
          :url="actOnSpecifiedCommercialTransactionsUrl"
        />
      </div>

      <div class="mt-6">
        <!--自転車手配（支払う）ボタン -->
        <TheButton
          text="お支払い方法を確定"
          @click-button="onClickPaymentSubmit()"
          :isDisabled="isPaymentButtonDisabled"
        />
      </div>

      <div class="text-W4 text-[11px] leading-[11px] text-gray text-left mt-2">
        登録メールアドレスにご利用情報をお送りします。
      </div>

      <div class="mt-6 p-4 bg-gray200 rounded-[8px] text-left">
        <div class="text-W7 text-[13px]">ご注意・ご案内</div>
        <div
          class="mt-2 grid grid-cols-[12px_auto] gap-x-2 gap-y-[2px] text-W4 text-[12px] leading-[18px] text-gray500"
        >
          <template v-for="notice in noticeList" :key="notice.num">
            <!-- 番号 -->
            <div>{{ notice.num }}.</div>
            <!-- 本文 -->
            <div class="whitespace-pre-line">{{ notice.text }}</div>
          </template>
        </div>
      </div>
    </div>
    <!-- TDS説明モーダル -->
    <TdsExplanationModal
      v-if="isShowTdsExplanationModal"
      @onClickOk="onClickTdsExplanationOk($event)"
    />
    <!-- 決済実行/手配実施モーダル -->
    <Modal
      v-if="isShowPaymentConfirmationModal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-8 pb-6">
        <div class="text-W5 text-[17px] leading-[25.5px]">
          下記のボタンを押すと決済を実行して自転車を手配します。
        </div>
        <div class="flex justify-center">
          <img
            src="@/assets/arrangementIcon/bicycle/MobilityIconBicycle.svg"
            class="h-[190px] my-6"
          />
        </div>
        <TheButton
          class="mb-4"
          buttonType="primary"
          text="自転車を手配（支払う）"
          @click-button="requestBicycleOrder()"
        />
        <TheButton
          buttonType="secondary"
          text="もどる"
          @click-button="isShowPaymentConfirmationModal = false"
        />
      </div>
    </Modal>
    <!-- 認証ページ遷移失敗モーダル -->
    <Modal
      v-if="isOpenTdsPageFailed"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-8 pb-6">
        <div class="text-W5 text-[17px] leading-[25.5px] mb-2">
          外部ブラウザで認証画面を表示することができませんでした。
        </div>
        <TheButton text="閉じる" @click-button="isOpenTdsPageFailed = false" />
      </div>
    </Modal>
    <!-- 利用規約ポップアップ -->
    <Modal
      v-if="isShowBicyclePolicy"
      class="modal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="p-5 mt-safe-area mb-[var(--bottom-safe-are-height)]">
        <!-- テキスト -->
        <div class="text-W7 text-[15px] leading-[15px] mx-auto">
          サービス利用規約
        </div>
        <!-- 規約本文 -->
        <BaseBox
          class="mt-[11px] border-[1px] border-gray700"
          :style="{
            height: calcTermsContentsHeight,
            'max-height': calcTermsContentsHeight,
          }"
        >
          <template v-slot:explanation>
            <div
              id="termsContents"
              class="p-2 max-h-full text-W4 text-[14px] text-gray500 leading-[21px] overflow-y-auto"
            >
              <span class="whitespace-pre-wrap">{{ terms }}</span>
            </div>
          </template>
        </BaseBox>
        <!-- ボタン -->
        <div class="pt-4 m-auto">
          <TheButton
            text="閉じる"
            class="text-W6"
            @click-button="isShowBicyclePolicy = false"
          />
        </div>
      </div>
    </Modal>
    <!-- クレジットカード関連エラーポップアップ -->
    <CreditCardErrorModal />
    <!-- 自転車手配失敗ポップアップ -->
    <Modal
      v-if="isArrangementFailedPopupFlg"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="py-6 px-5">
        <div class="font-bold mt-3">自転車の手配ができませんでした。</div>
        <div class="flex justify-center mt-4 items-center">
          <div
            class="w-1/2 py-4 the-button-secondary text-W7 text-[15px] leading-[15px] mr-3"
            @click="clickClose(true)"
          >
            手配をやめる
          </div>
          <TheButton
            text="再手配"
            class="w-1/2"
            @click-button="reBookingBicycle()"
          />
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
/**
 * 自転車予約 お支払い
 */
import Header from '@/components/organisms/Header.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import SelectedPaymentCard from '@/components/molecules/SelectedPaymentCard.vue'
import Modal from '@/components/Modal.vue'
import CreditCardErrorModal from '@/modals/CreditCardErrorModal.vue'
import TextForm from '@/components/TextFormV2.vue'
import TextFormWithValidation from '@/components/molecules/TextFormWithValidationV2.vue'
import BaseLink from '@/components/atoms/BaseLinkV2.vue'
import BaseCheckBox from '@/components/atoms/BaseCheckBoxV2.vue'
import ArrangementUtil from '@/mixins/arrangementUtil.js'
import Util from '@/mixins/util.js'
import NativeUtil from '@/mixins/nativeUtil.js'
import deepcopy from 'deepcopy'
import BaseBox from '@/components/atoms/BaseBox.vue'
import TdsExplanationModal from '@/modals/TdsExplanationModal.vue'
import {
  getIsSkipTdsExplanationFromLocalStorage,
  setIsSkipTdsExplanationFromLocalStorage,
} from '@/utils/localStorageUtil'

// ご注意・ご案内テキストリスト
const NOTICE_LIST = [
  {
    num: 1,
    text: '自転車は、車道が原則、歩道は例外',
  },
  {
    num: 2,
    text: '車道は左側を通行（車道の右側通行禁止）',
  },
  {
    num: 3,
    text: '歩道は歩行者優先で、自転車は車道寄りを徐行',
  },
  {
    num: 4,
    text:
      '安全ルールを守る\n' +
      '飲酒運転・二人乗り・並進の禁止\n' +
      '夜間はライトを点灯\n' +
      '信号遵守と交差点での一時停止・安全確認',
  },
  {
    num: 5,
    text:
      '子どもはヘルメットを着用\n' +
      '子どもだけでなく大人の方もヘルメットを着用しましょう！',
  },
]

const BicycleReservationPaymentConfirm = {
  name: 'BicycleReservationPaymentConfirm',
  components: {
    Header,
    TheButton,
    SelectedPaymentCard,
    Modal,
    CreditCardErrorModal,
    TextForm,
    TextFormWithValidation,
    BaseLink,
    BaseCheckBox,
    BaseBox,
    TdsExplanationModal,
  },
  mixins: [ArrangementUtil, Util, NativeUtil],
  data() {
    return {
      email: '', // メールアドレス
      tel: '', // 電話番号
      isTelError: false, // 電話番号エラー
      priorityCard: {}, // 優先使用されるクレジットカード情報
      isApplyPolicy: false, // 利用規約同意状況
      isShowBicyclePolicy: false, // 自転車利用規約ポップアップ表示フラグ
      isShowTdsExplanationModal: false, // 3DS認証説明モーダル表示フラグ
      isShowPaymentConfirmationModal: false, // 決済確認モーダル表示フラグ
      isOpenTdsPageFailed: false, // 認証ページ遷移失敗モーダル表示フラグ
      isArrangementFailedPopupFlg: false, // 予約失敗ポップアップフラグ
      creditOrderId: '', // 決済ID
      planInfo: deepcopy(
        this.$store.getters['MobilityReservationStore/planInfoWithCommadCharge']
      ), // プラン情報
      actOnSpecifiedCommercialTransactionsUrl:
        process.env.VUE_APP_NAMO_ACT_ON_SPECIFIED_COMMERCIAL_TRANSACTIONS_URL, // 特定商取引法に基づく表記 URL
      docomoBikeShareAgreementUrl: '#', // ドコモ・バイクシェア 利用規約
      docomoBikeSharePolicyUrl:
        process.env.VUE_APP_DOCOMO_BIKE_SHARE_PRIVACY_POLICY_URL, // ドコモ・バイクシェア プライバシーポリシー
    }
  },
  computed: {
    /**
     * 自転車利用規約URL
     */
    terms() {
      return this.$store.state.MobilityReservationStore.bicycle
        .selectedCycleTerms
    },
    /**
     * 利用規約コンテンツの表示領域高(px)
     */
    calcTermsContentsHeight() {
      const modalPaddingY = 48 // モーダル外の余白
      const TermsContentsMarginTop = 46 // 規約領域上辺からモーダル上辺までの高さ
      const TermsContentsMarginBottom = 83 // 規約領域下辺からモーダル下辺までの高さ
      const safeAreaHeight =
        this.$store.state.topSafeAreaHeight +
        this.$store.state.bottomSafeAreaHeight

      const totalMargins =
        modalPaddingY +
        TermsContentsMarginTop +
        TermsContentsMarginBottom +
        safeAreaHeight

      return `calc(100vh - ${totalMargins}px)`
    },
    /**
     * 遷移元画面情報
     */
    screenTransitionSource() {
      return this.$store.state.MobilityReservationStore.screenTransitionSource
    },
    /**
     * 遷移元フッターID
     */
    screenTransitionFooterId() {
      return this.screenTransitionSource.footerId
    },
    /**
     * お支払い方法を保持しているか
     * @returns {Boolean} お支払い方法保持しているか
     */
    hasPriorityCard() {
      return !this.isEmpty(this.priorityCard)
    },
    /**
     * 自転車手配（支払う）ボタン非活性
     */
    isPaymentButtonDisabled() {
      // 入力情報にエラがーある場合は非活性
      if (this.isTelError) {
        return true
      }
      // 未同意の場合は非活性
      if (!this.isApplyPolicy) {
        return true
      }
      // お支払い方法を保持していなければ非活性
      if (!this.hasPriorityCard) {
        return true
      }
      return false
    },
    /**
     * ご利用料金
     */
    formattedPrice() {
      const basicCharge = this.planInfo.basic.basicCharge
      const basicPeriod = this.planInfo.basic.basicPeriod
      const useCharge = this.planInfo.use.useCharge
      const usePeriod = this.planInfo.use.usePeriod
      return `${basicCharge}円/${basicPeriod}分（延長${useCharge}円/${usePeriod}分）`
    },
    /**
     * ご注意・ご案内テキスト集
     */
    noticeList() {
      return NOTICE_LIST
    },
  },
  created() {
    // 優先するカード情報をStorageから取得しStoreに保持
    this.$store.dispatch('CreditCardsStore/loadLocalPriorityCardSeq')
    // 使用対象のクレジットカード情報を取得
    this.priorityCard = this.$store.getters['CreditCardsStore/selectedCard']

    this.importUserInfo()
    // webViewの場合はFG復帰時の生体認証失敗処理を定義
    if (this.isWebView()) {
      this.defineResumeBioAuthFailedMethod()
    }
  },
  beforeUnmount() {
    // webViewの場合は生体認証失敗処理定義をwindowから削除
    if (this.isWebView()) {
      delete window.resumeBioAuthFailed
    }
  },
  methods: {
    /**
     * 「お支払い方法を確定」押下時処理
     * 3DS認証に対する説明モーダルを表示する。
     * ※「次回以降表示しない」を有効の場合、上記をSKIPし3DS本人認証APIを実行する
     */
    onClickPaymentSubmit() {
      const isSkip = getIsSkipTdsExplanationFromLocalStorage().bicycle
      if (isSkip) {
        // スキップを有効化している場合は、3DS本人認証APIを実行
        this.executeTdsChallenge()
      } else {
        // スキップを有効化していない場合は、3DSに対する説明モーダルを表示
        this.isShowTdsExplanationModal = true
      }
    },
    /**
     *  3DS認証に対する説明モーダルで「OK」押下時処理
     * 「次回以降表示しない」の選択情報を保持し、3DS本人認証APIを実行する
     * @param {Boolean} isNoDisplayNextTime 「次回以降表示しない」の有効可否
     */
    onClickTdsExplanationOk(isNoDisplayNextTime) {
      // 「次回以降確認しない」の情報をストレージ保持
      setIsSkipTdsExplanationFromLocalStorage('bicycle', isNoDisplayNextTime)
      // 本人認証実施
      this.executeTdsChallenge()
    },
    /**
     * 本人認証APIを実行
     * ・成功時：認証ページに外部リンク遷移を行い、表示モーダルを切り替え
     * ・失敗時(failed)：返却された情報に応じてエラーモーダル表示
     * ・失敗時(error)：共通エラーモーダル表示
     */
    executeTdsChallenge() {
      this.$store.commit('startLoading')
      const cardSeq = this.priorityCard.cardSeq
      const amount = 330
      // API実行後の共通処理
      const finalAction = () => {
        this.$store.commit('endLoading')
        this.isShowTdsExplanationModal = false
      }

      // 本人認証API実行
      this.$store.dispatch('CreditCardsStore/registerPaymentTransaction', {
        cardSeq: cardSeq,
        amount: amount,
        success: async (result) => {
          // 決済IDを保持
          this.creditOrderId = result.creditOrderId
          try {
            // 返却されたURLに外部ブラウザで表示
            await this.openExternalUrl(result.redirectUrl)
            // 同時にモーダルの表示切り替え
            this.isShowPaymentConfirmationModal = true
          } catch (e) {
            // 外部ブラウザ表示失敗時のモーダルを表示
            this.isOpenTdsPageFailed = true
          }
          finalAction()
        },
        failed: (response) => {
          // 返却されたエラー内容を解析してエラーモーダル表示する。
          try {
            this.$store.dispatch('CreditCardsStore/apiFailedAction', response)
          } catch (e) {
            // 決済登録処理失敗用のモーダル表示
            this.isArrangementFailedPopupFlg = true
          }
          finalAction()
        },
        error: (e) => {
          finalAction()
          throw e
        },
      })
    },
    /**
     * 自転車予約準備処理
     * LocalStorageやStoreに対し、必要情報を設定
     */
    setupBicycleReserve() {
      // ローカルストレージに使用するクレジットカード連番を格納
      this.$store.dispatch(
        'CreditCardsStore/updateLocalPriorityCardSeq',
        this.priorityCard.cardSeq
      )

      // ストアに入力内容保存
      this.$store.commit('MobilityReservationStore/updateBicycleUserInfo', {
        email: this.email,
        tel: this.tel,
      })
    },
    /**
     * 前の画面に遷移
     */
    goToBackPage() {
      // ユーザー情報の初期化
      this.$store.commit('MobilityReservationStore/updateBicycleUserInfo', {})
      // 利用規約の初期化
      this.$store.commit(
        'MobilityReservationStore/updateSelectedCycleTerms',
        ''
      )
      this.$router.push({name: this.$config.DISPLAY_BICYCLE_RESERVATION_SELECT})
    },
    /**
     * 自転車予約実行処理
     */
    requestBicycleOrder() {
      // 事前準備
      this.setupBicycleReserve()
      // ローディング開始
      this.$store.commit('startLoading')
      // 決済実行確認モーダルを非表示
      this.isShowPaymentConfirmationModal = false

      // 自転車予約API実行
      this.$store.dispatch('MobilityReservationStore/postBicycleReservation', {
        creditOrderId: this.creditOrderId,
        success: () => {
          this.$store.commit('endLoading')
          // 予約確定のステータスに更新
          this.$store.commit(
            'MobilityReservationStore/updateBicycleReservationStatus',
            this.$config.BICYCLE_STATUS_CODE.COMPLETE_RESERVATION
          )
          // 予約確定画面に遷移
          const page = this.$config.DISPLAY_BICYCLE_RESERVATION_CONFIRMED
          this.$router.push({name: page})

          // 遷移元フッターIDが手配IDの場合は、遷移元を検索TOP画面へ変更
          if (
            this.screenTransitionFooterId === this.$config.FOOTER_ID_ARRANGEMENT
          ) {
            this.$store.commit(
              'MobilityReservationStore/updateScreenTransitionSource',
              {
                displayName: this.$config.DISPLAY_SEARCH_TOP,
                footerId: this.$config.FOOTER_ID_SPOT,
              }
            )
          }
        },
        failed: (apiResponse) => {
          this.$store.commit('endLoading')
          try {
            // クレジットカードエラーモーダル表示判定処理を実行
            this.$store.dispatch(
              'CreditCardsStore/apiFailedAction',
              apiResponse
            )
          } catch (e) {
            // 手配失敗モーダルを表示
            this.isArrangementFailedPopupFlg = true
          }
        },
        error: (e) => {
          throw e
        },
      })
    },
    /**
     * 自転車再手配
     */
    reBookingBicycle() {
      this.isArrangementFailedPopupFlg = false
      this.executeTdsChallenge()
    },
    /**
     * ポート選択(マップ)画面に戻る
     */
    gotoBackPortOnMapPage() {
      // 詳細情報の初期化
      this.$store.commit('MobilityReservationStore/updateSelectedParkInfo', {})
      this.$store.commit('MobilityReservationStore/updateSelectedCycleInfo', {})
      this.$store.commit('MobilityReservationStore/updateParkDetailInfo', {})
      // ユーザー情報の初期化
      this.$store.commit('MobilityReservationStore/updateBicycleUserInfo', {})
      // 利用規約の初期化
      this.$store.commit(
        'MobilityReservationStore/updateSelectedCycleTerms',
        ''
      )
      this.$router.push({
        name: this.$config.DISPLAY_BICYCLE_RESERVATION_PORT_ON_MAP,
      })
    },
    /**
     * Storeからユーザー情報を取得し、dataにインポートする
     */
    importUserInfo() {
      const bicycleUserInfo =
        this.$store.state.MobilityReservationStore.bicycle.userInfo
      const userInfo = this.$store.state.user.info

      this.tel = bicycleUserInfo.tel || userInfo.tel
      this.email = userInfo.email
    },
    /**
     * クレジットカード選択画面へ遷移する
     */
    goToCreditCardSelect() {
      // ストアに入力内容保存
      this.$store.commit('MobilityReservationStore/updateBicycleUserInfo', {
        email: this.email,
        tel: this.tel,
      })
      // 遷移元のパスをstoreに登録
      this.$store.commit('CreditCardsStore/updateScreenTransitionSource', {
        displayName: this.$route.name,
      })
      this.$router.push({name: this.$config.DISPLAY_PAYMENT_METHOD_SELECT})
    },
    /**
     * クレジットカード登録画面へ遷移する
     */
    goToRegisterCreditCard() {
      // ストアに入力内容保存
      this.$store.commit('MobilityReservationStore/updateBicycleUserInfo', {
        email: this.email,
        tel: this.tel,
      })
      // 遷移元のパスをstoreに登録
      this.$store.commit('CreditCardsStore/updateScreenTransitionSource', {
        displayName: this.$route.name,
      })
      this.$router.push({name: this.$config.DISPLAY_REGISTER_CREDIT_CARD})
    },
    /**
     * FG復帰時の生体認証失敗処理をwindowに定義
     */
    defineResumeBioAuthFailedMethod() {
      window.resumeBioAuthFailed = () => {
        this.clickClose(true)
      }
    },
  },
}
export default BicycleReservationPaymentConfirm
</script>
<style scoped></style>
