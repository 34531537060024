import {constant} from '@/const/const.js'

/**
 * 駅種別をもとにモビリティのアイコンを取得する
 * @param {*} type 駅種別
 * @param {*} linkId 路線ID
 * @param {*} lineName 路線名
 * @returns モビリティアイコン
 */
export function getMobilityIconFromStationType(type, linkId, lineName) {
  let mobility
  switch (type) {
    case 'station':
      mobility = 'local_train'
      break
    case 'airport':
      mobility = 'domestic_flight'
      break
    case 'port':
      mobility = 'ferry'
      break
    case 'busstop':
      mobility = 'local_bus'
      break
    case 'shuttle_busstop':
      mobility = 'shuttle_bus'
      break
    case 'highway_busstop':
      mobility = 'highway_bus'
      break
    default:
      mobility = 'local_train'
      break
  }
  // 汎用の路線アイコン取得処理で取得する
  return getMobilityIcon(mobility, linkId, lineName)
}

/**
 * 乗換情報から、モビリティをもとにアイコンの出し分けを行う
 * @param {*} transportInfo 乗換情報
 * @returns モビリティアイコン
 */
export function getMobilityIconFromTransportInfo(transportInfo) {
  return getMobilityIcon(
    transportInfo.move,
    transportInfo.transport &&
      transportInfo.transport.links &&
      transportInfo.transport.links[0].id, // eslint-disable-line no-magic-numbers
    transportInfo.lineName
  )
}

/**
 * モビリティをもとにアイコンの出し分けを行う
 * @param {*} mobility モビリティ
 * @param {*} linkId 路線のID
 * @param {*} lineName 路線名
 * @returns モビリティアイコン
 */
export function getMobilityIcon(mobility, linkId, lineName) {
  let fileName
  switch (mobility) {
    // 徒歩
    case 'walk':
      fileName = 'Icon_Move_Walk.svg'
      break
    // 車
    case 'car':
      fileName = 'Icon_Move_Car.svg'
      break
    // 自転車
    case 'bicycle':
      fileName = 'Icon_Move_Bicycle.svg'
      break
    // 飛行機
    case 'domestic_flight':
      fileName = 'Icon_Move_Flight.svg'
      break
    // フェリー
    case 'ferry':
      fileName = 'Icon_Move_Ferry.svg'
      break
    // バス
    case 'shuttle_bus':
    case 'local_bus':
    case 'highway_bus':
      fileName = 'Icon_Move_Bus.svg'
      break
    // 電車
    case 'superexpress_train':
    case 'sleeper_ultraexpress':
    case 'ultraexpress_train':
    case 'express_train':
    case 'rapid_train':
    case 'semiexpress_train':
    case 'local_train':
      fileName = getLinksNameIcon(linkId, lineName)
      break
    default:
      break
  }
  return fileName ? require('@/assets/' + fileName) : ''
}

/**
 * 該当の路線アイコンのファイル名を返却する
 * @param {*} linkId sectionsをそのまま渡す
 * @param {*} lineName sectionsをそのまま渡す
 * @returns モビリティアイコン
 */
export function getLinksNameIcon(linkId, lineName) {
  let fileName = 'Icon_Move_Train.svg'
  switch (linkId) {
    case '00000768': // 東京メトロ銀座線
      fileName = 'mobilityIcon/Icon_Train_Metro_G.svg'
      break
    case '00000766': // 東京メトロ丸ノ内線
    case '00000767': // 東京メトロ丸ノ内線(中野坂上-方南町)
      fileName = 'mobilityIcon/Icon_Train_Metro_M.svg'
      break
    case '00000773': // 東京メトロ日比谷線
      fileName = 'mobilityIcon/Icon_Train_Metro_H.svg'
      break
    case '00000771': // 東京メトロ東西線
      fileName = 'mobilityIcon/Icon_Train_Metro_T.svg'
      break
    case '00000769': // 東京メトロ千代田線
    case '00000770': // 東京メトロ千代田線(綾瀬-北綾瀬)
      fileName = 'mobilityIcon/Icon_Train_Metro_C.svg'
      break
    case '00000775': // 東京メトロ有楽町線
      fileName = 'mobilityIcon/Icon_Train_Metro_Y.svg'
      break
    case '00000774': // 東京メトロ半蔵門線
      fileName = 'mobilityIcon/Icon_Train_Metro_Z.svg'
      break
    case '00000772': // 東京メトロ南北線
      fileName = 'mobilityIcon/Icon_Train_Metro_N.svg'
      break
    case '00000776': // 東京メトロ副都心線
      fileName = 'mobilityIcon/Icon_Train_Metro_F.svg'
      break
    case '00000795': // 都営浅草線
      fileName = 'mobilityIcon/Icon_Train_Metro_A.svg'
      break
    case '00000793': // 都営三田線
      fileName = 'mobilityIcon/Icon_Train_Metro_I.svg'
      break
    case '00000792': // 都営大江戸線(環状部)
    case '00001015': // 都営大江戸線(放射部)
      fileName = 'mobilityIcon/Icon_Train_Metro_E.svg'
      break
    case '00000794': // 都営新宿線
      fileName = 'mobilityIcon/Icon_Train_Metro_S.svg'
      break
    case '00000790': // 東急東横線
      fileName = 'mobilityIcon/Icon_Train_Tokyu_TY.svg'
      break
    case '00000791': // 東急目黒線
      fileName = 'mobilityIcon/Icon_Train_Tokyu_MG.svg'
      break
    case '00000789': // 東急田園都市線
      fileName = 'mobilityIcon/Icon_Train_Tokyu_DT.svg'
      break
    case '00000787': // 東急大井町線
      fileName = 'mobilityIcon/Icon_Train_Tokyu_OM.svg'
      break
    case '00000788': // 東急池上線
      fileName = 'mobilityIcon/Icon_Train_Tokyu_IK.svg'
      break
    case '00000903': // 東急多摩川線
      fileName = 'mobilityIcon/Icon_Train_Tokyu_TM.svg'
      break
    case '00000786': // 東急世田谷線
      fileName = 'mobilityIcon/Icon_Train_Tokyu_SG.svg'
      break
    case '00000784': // 東急こどもの国線
      fileName = 'mobilityIcon/Icon_Train_Tokyu_KD.svg'
      break
    case '00000183': // JＲ東海道本線（東京-熱海）
      fileName = 'mobilityIcon/Icon_Train_JR_JT.svg'
      break
    case '00000123': // ＪＲ横須賀線
      fileName = 'mobilityIcon/Icon_Train_JR_JO.svg'
      break
    case '00000131': // ＪＲ京浜東北線
    case '00000137': // ＪＲ根岸線
      fileName = 'mobilityIcon/Icon_Train_JR_JK.svg'
      break
    case '00000124': // ＪＲ横浜線
      fileName = 'mobilityIcon/Icon_Train_JR_JH.svg'
      break
    case '00000191': // ＪＲ南武線(尻手-浜川崎)
    case '00000192': // ＪＲ南武線(川崎-立川)
      fileName = 'mobilityIcon/Icon_Train_JR_JN.svg'
      break
    case '00000179': // ＪＲ鶴見線(安善-大川)
    case '00000180': // ＪＲ鶴見線(浅野-海芝浦)
    case '00000181': // ＪＲ鶴見線(鶴見-扇町)
      fileName = 'mobilityIcon/Icon_Train_JR_JI.svg'
      break
    case '00000141': // ＪＲ山手線
      fileName = 'mobilityIcon/Icon_Train_JR_JY.svg'
      break
    case '00000176': // ＪＲ中央本線（東京-塩尻）
      // 中央本線は各停と快速がlinksでは判別不可のためnameを使う
      switch (lineName) {
        case 'ＪＲ中央線快速':
        case 'ＪＲ中央線特別快速':
        case 'ＪＲ中央本線（東京-塩尻）':
        case 'ＪＲ中央本線(東京-塩尻)':
          fileName = 'mobilityIcon/Icon_Train_JR_JC.svg'
          break
        default:
          // 各停など他の種別はJBにする
          fileName = 'mobilityIcon/Icon_Train_JR_JB.svg'
      }
      break
    case '00000162': // ＪＲ青梅線
    case '00000133': // ＪＲ五日市線
      fileName = 'mobilityIcon/Icon_Train_JR_JC.svg'
      break
    case '00000169': // ＪＲ総武線各停(御茶ノ水-錦糸町)
      fileName = 'mobilityIcon/Icon_Train_JR_JB.svg'
      break
    case '00000168': // ＪＲ総武本線(東京-銚子)
      // 総武本線は各停と快速がlinksでは判別不可のためnameを使う
      switch (lineName) {
        case 'ＪＲ中央総武線各停':
        case 'ＪＲ総武本線(東京-銚子)':
        case 'ＪＲ総武本線（東京-銚子）':
          fileName = 'mobilityIcon/Icon_Train_JR_JB.svg'
          break
        case 'ＪＲ総武本線快速（東京-銚子）':
        case 'ＪＲ総武本線快速(東京-銚子)':
          fileName = 'mobilityIcon/Icon_Train_JR_JO.svg'
          break
        default:
          break
      }
      break
    case '00000188': // ＪＲ宇都宮線〔東北本線〕・ＪＲ上野東京ライン
    case '00000136': // ＪＲ高崎線
      fileName = 'mobilityIcon/Icon_Train_JR_JU.svg'
      break
    case '00000139': // ＪＲ埼京線
      fileName = 'mobilityIcon/Icon_Train_JR_JA.svg'
      break
    case '00000151': // ＪＲ常磐線
      // 「ＪＲ常磐線」のみの場合は汎用アイコンを表示する
      switch (lineName) {
        case 'ＪＲ常磐線快速':
          fileName = 'mobilityIcon/Icon_Train_JR_JJ.svg'
          break
        case 'ＪＲ常磐線各停':
          fileName = 'mobilityIcon/Icon_Train_JR_JL.svg'
          break
        default:
          break
      }
      break
    case '00000132': // ＪＲ京葉線
      fileName = 'mobilityIcon/Icon_Train_JR_JE.svg'
      break
    case '00000201': // ＪＲ武蔵野線(西船橋-市川塩浜)
    case '00000202': // ＪＲ武蔵野線(府中本町-南船橋)
    case '00000920': // ＪＲ武蔵野線直通(大宮)
      fileName = 'mobilityIcon/Icon_Train_JR_JM.svg'
      break
    case '00000910': // ＪＲ湘南新宿ライン
      fileName = 'mobilityIcon/Icon_Train_JR_JS.svg'
      break
    case '00000286': // 京成押上線
    case '00000287': // 京成金町線
    case '00000288': // 京成千原線
    case '00000289': // 京成千葉線
    case '00000290': // 京成東成田線
    case '00000291': // 京成本線
    case '00000917': // 京成本線(京成成田-成田空港)
      fileName = 'mobilityIcon/Icon_Train_Shitestu_KS.svg'
      break
    case '00000294': // 京急久里浜線
    case '00000295': // 京急空港線
    case '00000296': // 京急逗子線
    case '00000297': // 京急大師線
    case '00000298': // 京急本線
      fileName = 'mobilityIcon/Icon_Train_Shitestu_KK.svg'
      break
    case '00000686': // 小田急小田原線
    case '00000687': // 小田急小田原線(渋沢-松田)
      fileName = 'mobilityIcon/Icon_Train_Shitestu_OH.svg'
      break
    case '00000274': // 京王線
    case '00000275': // 京王競馬場線
    case '00000276': // 京王高尾線
    case '00000277': // 京王新線
    case '00000278': // 京王相模原線
    case '00000279': // 京王動物園線
      fileName = 'mobilityIcon/Icon_Train_Shitestu_KO.svg'
      break
    case '00000273': // 京王井の頭線
      fileName = 'mobilityIcon/Icon_Train_Shitestu_IN.svg'
      break
    case '00000808': // 東武東上線
      fileName = 'mobilityIcon/Icon_Train_Shitestu_TJ.svg'
      break
    case '00000798': // 東武伊勢崎線
    case '00000999': // 東武伊勢崎線(押上-曳舟)
      fileName = 'mobilityIcon/Icon_Train_Shitestu_TI.svg'
      break
    case '00000797': // りんかい線
      fileName = 'mobilityIcon/Icon_Train_Shitestu_R.svg'
      break
    case '00000725': // 西武池袋線
      fileName = 'mobilityIcon/Icon_Train_Shitestu_SI.svg'
      break
    case '00000721': // 西武新宿線
      fileName = 'mobilityIcon/Icon_Train_Shitestu_SS.svg'
      break
    default:
      break
  }
  return fileName
}

/**
 * 決済ステータスNG判定
 * @param {String} paymentStatus 決済ステータス
 * @returns 決済ステータスNG判定結果
 */
export function determiningPaymentFailing(paymentStatus) {
  const suspendList = [
    constant.USER_PAYMENT_STATUS.SUSPEND,
    constant.USER_PAYMENT_STATUS.PAYMENT_NG,
  ]
  return suspendList.includes(paymentStatus)
}

/**
 * 路線情報を纏める
 * 一つの駅名に複数のバス停系交通タイプを持っている場合、路線リストを纏める
 * @param {Array} stations ワード検索した駅情報リスト
 * @return {Array} 成型した駅情報リスト
 */
export function consolidateLink(stations) {
  let resList = []
  const busList = [
    constant.STATION_TYPE.BUSSTOP,
    constant.STATION_TYPE.SHUTTLE_BUSSTOP,
    constant.STATION_TYPE.HIGHWAY_BUSSTOP,
  ]
  for (let i = 0; i < stations.length; i++) {
    const targetIndex = resList.findIndex((res) => {
      return res?.nodeId == stations[i].nodeId && res?.name == stations[i].name
    })
    // 路線タイプがバス停系で同じ駅名/駅IDであれば路線情報を一つに纏める
    // eslint-disable-next-line no-magic-numbers
    if (busList.includes(stations[i].types) && targetIndex !== -1) {
      resList[targetIndex].links.push(...stations[i].links)
    } else {
      resList.push(stations[i])
    }
  }
  return resList
}
