import log from 'loglevel'
import util from './util.js'

// iOS WebViewのユーザーエージェント
const IOS_WEBVIEW = 'iOS_WebView'

// Android WebViewのユーザーエージェント
const ANDROID_WEBVIEW = 'Android_WebView'

const RESUME_EVENT_NAME = 'onresume'

/**
 * CallBackタイプリスト
 */
export const NATIVE_CALLBACK_TYPE = {
  getDeviceToken: 'getDeviceToken',
  bioAuth: 'bioAuth',
  cancelMembership: 'cancelMembership',
  isGrantedCameraPermission: 'isGrantedCameraPermission',
  updateState: 'updateState',
  convertImage: 'convertImage',
  showLicensePage: 'showLicensePage',
}

/**
 * GoogleAnalyticsに送信する際のログイベント名
 * ※既存のイベント名と合わせるため、スネークケースで定義
 */
export const GA_LOG_EVENTS = {
  JUMP_EXTERNAL_PAGE: 'jump_external_page', // 外部リンク遷移用ログイベント名
}

/**
 * 指定のIDがCallBackリストに存在するか
 * @param {*} id
 * @returns true:存在する false:存在しない
 */
const validCallBackType = (id) => {
  return (
    Object.keys(NATIVE_CALLBACK_TYPE).find(
      (key) => NATIVE_CALLBACK_TYPE[key] === id
    ) != undefined
  )
}

/**
 * Flutter側に定義されたメソッドを呼び出す
 * @param {*} id 連携データの分類ID
 * @param {*} data 連携するデータ
 */
const callFlutterHandler = async (id, data) => {
  // Flutter Call の最大試行回数
  const MAX_TRY_COUNT = 5
  // Flutter Call の試行回数間隔（ミリ秒）
  const INTERVAL_TIME = 500
  for (let i = 1; i <= MAX_TRY_COUNT; i++) {
    try {
      if (window.flutter_inappwebview.callHandler) {
        return await window.flutter_inappwebview.callHandler(id, data)
      } else if (window.flutter_inappwebview._callHandler) {
        return await window.flutter_inappwebview._callHandler(id, data)
      } else {
        throw new Error('Native call error')
      }
    } catch (e) {
      if (i == MAX_TRY_COUNT) {
        throw e
      } else {
        // ページ表示直後など、Flutter側の準備が間に合わなかった場合に例外が発生するため、時間をおいて再試行する
        await new Promise((resolve) => setTimeout(resolve, INTERVAL_TIME))
      }
    }
  }
}

/**
 * GoogleAnalyticsへログイベントを送信する
 * @param {String} eventName イベント名
 * @param {Map<String,Object>} parameters パラメータ
 */
async function logEventForGoogleAnalytics(eventName, parameters) {
  try {
    await callFlutterHandler('firebaseLogEvent', {eventName, parameters})
  } catch (e) {
    // GoogleAnalyticsに送信失敗した場合、ユーザーに知らせる必要はないので握りつぶし
    log.warn(`Send information to GoogleAnalytics Failed. [${eventName}]`, e)
  }
}

export default {
  methods: {
    /**
     * UserAgentを基にiOSのWebViewかを判定する
     * @returns true:iOS WebView false:それ以外
     */
    isIosWebView() {
      const ua = navigator.userAgent
      return ua.includes(IOS_WEBVIEW) || window.platform === 'ios'
    },

    /**
     * UserAgentを基にAndroidのWebViewかを判定する
     * @returns true:Android WebView false:それ以外
     */
    isAndroidWebView() {
      const ua = navigator.userAgent
      return ua.includes(ANDROID_WEBVIEW) || window.platform === 'android'
    },

    /**
     * FlutterのWebViewかを判定する
     * @returns true:Flutter WebView false:それ以外
     */
    isFlutterWebView() {
      return !!window.flutter_inappwebview
    },

    /**
     * WebViewかを判定する
     * @returns true:WebView false:それ以外
     */
    isWebView() {
      return (
        this.isIosWebView() ||
        this.isAndroidWebView() ||
        this.isFlutterWebView()
      )
    },

    /**
     * WKWebViewにデータを連携する
     * @param {*} id 連携データの分類ID
     * @param {*} data 連携するデータ
     */
    async linkNativeCallback(id, data) {
      // 無効なIDの場合は処理を終了する
      if (!validCallBackType(id)) {
        return
      }

      if (this.isFlutterWebView()) {
        // Flutterにデータ連携する
        return callFlutterHandler(id, data)
      } else if (this.isIosWebView()) {
        /* eslint-disable no-undef */
        if (util.methods.isNull(webkit)) {
          // webkitが定義されていない場合、終了する
          return
        }
        // データを連携する
        webkit.messageHandlers.linkNativeCallback.postMessage({
          id: id,
          data: data,
        })
        /* eslint-enable no-undef */
      } else if (this.isAndroidWebView()) {
        /* eslint-disable no-undef */
        if (util.methods.isNull(androidApp)) {
          // androidAppが定義されていない場合、終了する
          return
        }
        androidApp.postMessage(
          JSON.stringify({
            id: id,
            data: data,
          })
        )
        /* eslint-enable no-undef */
      }
    },
    /**
     * 生体認証結果受け取りメソッド定義処理
     * @param {Function} success 成功コールバック
     * @param {Function} failed 失敗コールバック
     */
    defineGetBioAuthResultMethod(success, failed) {
      window.getBioAuthResult = (result) => {
        // 下記の書き方しないとAndroidではfalseでもtrueの分岐に入る
        if (result === true) {
          success()
        } else {
          failed()
        }
      }
    },
    /**
     * カメラ権限結果取得
     * @returns カメラの権限が許可されていればtrue
     */
    async checkCameraPermission() {
      return new Promise((resolve) => {
        window.cameraPermission = (result) => {
          delete window.cameraPermission
          resolve(result === true)
        }
        this.linkNativeCallback('isGrantedCameraPermission')
      })
    },
    /**
     * VueのStateをnativeに受け渡す
     */
    async updateState(state) {
      this.linkNativeCallback('updateState', state)
    },
    /**
     * 画像変換
     * @param {string} base64 base64URI形式の画像データ
     * @returns jpeg変換されたbase64URI形式の画像データ
     */
    async convertImage(base64) {
      return new Promise((resolve) => {
        window.convertImageResult = (result) => {
          delete window.convertImageResult
          resolve(result)
        }
        this.linkNativeCallback('convertImage', base64)
      })
    },
    /**
     * Nativeから呼び出されるアプリ復帰イベントを登録する
     */
    defineWindowResumeEvent() {
      window.onResume = () => {
        window.dispatchEvent(new CustomEvent(RESUME_EVENT_NAME))
      }
    },
    /**
     * アプリ復帰時に呼び出される関数を追加する
     * @param {Function} func 追加する関数
     */
    addResumeEvent(func) {
      window.addEventListener(RESUME_EVENT_NAME, func, false)
    },
    /**
     * アプリ復帰時に呼び出される関数を削除する
     * @param {Function} func 削除する関数
     */
    removeResumeEvent(func) {
      window.removeEventListener(RESUME_EVENT_NAME, func)
    },
    /**
     * ライセンスページを表示する
     */
    async showLicensePage() {
      const licenses = require('@/licenses.json')
      this.linkNativeCallback('showLicensePage', licenses)
    },
    /**
     * 位置情報を取得する
     */
    async getCurrentPosition() {
      return await callFlutterHandler('getCurrentPosition')
    },
    /**
     * 位置情報監視を開始する
     * @param {Function} func 位置情報が更新されたときに呼ばれる関数
     */
    async watchPosition(func) {
      window.watchPositionCallback = (position) => {
        func(position)
      }
      return await callFlutterHandler('watchPosition')
    },
    /**
     * Nativeの情報を取得する
     * @returns Nativeの情報
     */
    async watchNativeInfo() {
      if (this.isFlutterWebView()) {
        return await callFlutterHandler('watchNativeInfo')
      } else {
        return {}
      }
    },
    /**
     * コンパス情報（向いている方向）を取得する
     * @returns 向いている方向
     */
    async getHeading() {
      if (this.isFlutterWebView()) {
        return callFlutterHandler('getHeading')
      }
      return null
    },
    /**
     * GoogleAnalyticsへ"外部リンク遷移時"のログイベントを送信する
     * @param {String} linkName 外部リンク先の名称
     */
    async jumpExternalPageLogEventForGA(linkName) {
      // アプリ起動時ではない場合は何も行わない
      if (!this.isFlutterWebView()) return

      // GoogleAnalyticsに外部リンク遷移のログイベントを送信する
      await logEventForGoogleAnalytics(GA_LOG_EVENTS.JUMP_EXTERNAL_PAGE, {
        name: linkName,
      })
    },
    /**
     * 外部ブラウザ遷移
     *
     * ※iOSのWebViewでは、Appleの制約によりユーザー操作起因以外での遷移が制限されており、window.open()では外部ブラウザが立ち上がらない。
     * 対策として、Native側から間接的に外部ブラウザを起動する形にすることで外部ブラウザ起動を行う。
     *
     * ※実機に外部ブラウザがない(もしくは無効)場合、Flutter側でエラーが発生する。
     * 素直にエラーを受け取ると共通のリトライ処理が発生するが、リトライで改善されるようなものではないため、
     * エラーではなく成功可否を受け取る形とし、共通りトライ処理を回避する。
     *
     * @param {String} url 遷移先のURL
     */
    async openExternalUrl(url) {
      if (this.isFlutterWebView()) {
        // WebViewの場合はNativeから外部ブラウザ起動
        const result = await callFlutterHandler('openUrl', url)
        // 遷移処理に失敗した場合はエラー
        if (!result) throw new Error()
      } else {
        // 上記以外はVueから直接外部ブラウザを起動
        window.open(url, '_blank', 'noreferrer')
      }
    },
  },
}
