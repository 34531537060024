<template>
  <div class="h-full overflow-y-auto">
    <!-- ヘッダー -->
    <Header
      headerTitle="お支払い方法を登録"
      :isShowPrevious="true"
      @clickPrevious="goToBackPage()"
    />
    <!-- コンテンツ -->
    <div class="bg-white mx-5">
      <div class="text-left text-W4">
        <div class="text-[15px] mt-[21px]">
          以下のカードをご利用いただけます。
        </div>
        <div class="mt-3">
          <img
            class="h-5"
            src="@/assets/payment/cardLogo/creditCardsLogo.png"
            alt="Visa, Mastercard, JCB, American Express, Diners Club INTERNATIONAL"
          />
        </div>
      </div>

      <!-- 入力フォーム -->
      <div class="text-left mt-6 mb-10">
        <!-- カード名義人 -->
        <div class="mb-4">
          <div class="text-W7 text-[13px] leading-none mb-2">カード名義人</div>
          <TextFormWithValidationV2
            ref="CardHolderForm"
            inputClass="bg-gray200 border-gray200"
            :text="cardHolder"
            :validationType="['required', 'creditCardHolder']"
            maxlength="50"
            placeholder="TARO TOKAI"
            errorMessage="正しいカード名義人を入力してください。"
            v-model:isError="isErrorCardHolder"
            @input-text="cardHolder = $event"
          />
        </div>
        <!-- カード番号 -->
        <div class="mb-4">
          <div class="text-W7 text-[13px] leading-none mb-2">カード番号</div>
          <TextFormWithValidationV2
            ref="CardNumberForm"
            inputClass="bg-gray200 border-gray200"
            :text="cardNumber"
            inputmode="numeric"
            :validationType="['required', 'creditCardNumber']"
            maxlength="16"
            placeholder="1234567890123456"
            errorMessage="正しいカード番号を入力してください。"
            v-model:isError="isErrorCardNumber"
            @input-text="cardNumber = $event"
            name="cardNumber"
            autocomplete="cc-number"
            id="cardNumber"
          />
        </div>
        <!-- 有効期限 -->
        <div class="mb-4">
          <div class="text-W7 text-[13px] leading-none mb-2">
            有効期限（月/年）
          </div>
          <div class="flex">
            <TextFormWithValidationV2
              ref="ExpireMonthForm"
              class="w-1/2 mr-1"
              inputClass="bg-gray200 border-gray200"
              :text="expireMonth"
              inputmode="numeric"
              :validationType="['required', 'creditCardExpireMonth']"
              maxlength="2"
              placeholder="MM"
              v-model:isError="isErrorExpireMonth"
              @input-text="expireMonth = $event"
              name="cardExpirationMonth"
              autocomplete="cc-exp-month"
              id="cardExpirationMonth"
            />
            <TextFormWithValidationV2
              ref="ExpireYearForm"
              class="w-1/2 ml-1"
              inputClass="bg-gray200 border-gray200"
              :text="expireYear"
              inputmode="numeric"
              :validationType="['required', 'creditCardExpireYear']"
              maxlength="2"
              placeholder="YY"
              v-model:isError="isErrorExpireYear"
              @input-text="expireYear = $event"
              name="cardExpirationYear"
              autocomplete="cc-exp-year"
              id="cardExpirationYear"
            />
          </div>
          <div
            v-show="isErrorExpireMonth || isErrorExpireYear"
            data-name="expire-month-error-message"
            class="mt-2 text-left text-danger300 text-W2 leading-[13px]"
          >
            正しい有効期限を入力してください。
          </div>
        </div>
        <!-- セキュリティコード -->
        <div class="mb-[21px]">
          <div class="text-W7 text-[13px] leading-none mb-2 flex items-center">
            <div class="mr-2">セキュリティコード</div>
            <div @click="isShowHelpPopup = true">
              <IconHelp class="w-4 h-4" />
            </div>
          </div>
          <div class="flex">
            <TextFormWithValidationV2
              ref="SecurityCodeForm"
              class="w-1/2 mr-1"
              inputClass="bg-gray200 border-gray200"
              :text="securityCode"
              inputmode="numeric"
              :validationType="['required', 'creditCardSecurityCode']"
              maxlength="4"
              placeholder="123"
              v-model:isError="isErrorSecurityCode"
              @input-text="securityCode = $event"
            />
            <div class="w-1/2 ml-1"></div>
          </div>
          <div
            v-show="isErrorSecurityCode"
            data-name="security-code-error-message"
            class="mt-2 text-left text-danger300 text-W2 leading-[13px]"
          >
            正しいセキュリティコードを入力してください。
          </div>
        </div>
        <!-- 特定商取引法リンク -->
        <div class="mb-[21px]">
          <div class="mt-4"></div>
          <BaseLinkV2
            label="特定商取引法に基づく表記"
            :url="actOnSpecifiedCommercialTransactionsUrl"
          />
        </div>

        <TheButton
          text="クレジットカードを登録する"
          :isDisabled="!entryEnabled()"
          @click-button="onClickRegisterCard()"
        />
      </div>
    </div>

    <!-- ポップアップ -->
    <VariableHeightPanel
      :isShow="isShowHelpPopup"
      :isShowCloseIcon="true"
      slideInStartPosition="-265px"
      @closePanel="isShowHelpPopup = false"
    >
      <template v-slot:header>
        <div class="text-center px-5">セキュリティコードについて</div>
      </template>
      <template v-slot:body>
        <div class="mx-5">
          <div class="w-[136px] h-[90px] mx-auto mt-5">
            <img src="@/assets/payment/creditCards/securityCode.png" alt="" />
          </div>
          <div class="text-left text-W4 text-[13px] my-4">
            クレジットカード裏面にある3桁または4桁の番号です。
            カードの種類によっては表面に記載されている場合があります。
          </div>
        </div>
      </template>
    </VariableHeightPanel>
    <!-- クレジットカード関連エラーポップアップ -->
    <CreditCardErrorModal />
  </div>
</template>

<script>
import BaseLinkV2 from '@/components/atoms/BaseLinkV2.vue'
import CreditCardErrorModal from '@/modals/CreditCardErrorModal.vue'
import Header from '@/components/organisms/Header.vue'
import IconHelp from '@/components/icons/payment/creditCards/IconHelp.vue'
import TextFormWithValidationV2 from '@/components/molecules/TextFormWithValidationV2.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import VariableHeightPanel from '@/components/organisms/VariableHeightPanel.vue'

const RegisterCreditCard = {
  name: 'RegisterCreditCard',
  components: {
    BaseLinkV2,
    CreditCardErrorModal,
    Header,
    IconHelp,
    TextFormWithValidationV2,
    TheButton,
    VariableHeightPanel,
  },
  data() {
    return {
      // 特定商取引法に基づく表記 URL
      actOnSpecifiedCommercialTransactionsUrl:
        process.env.VUE_APP_NAMO_ACT_ON_SPECIFIED_COMMERCIAL_TRANSACTIONS_URL,
      // カード名義人
      cardHolder: '',
      // カード名義人のエラーメッセージ表示フラグ
      isErrorCardHolder: false,
      // カード番号
      cardNumber: '',
      // カード番号のエラーメッセージ表示フラグ
      isErrorCardNumber: false,
      // 有効期限の月
      expireMonth: '',
      // 有効期限(月)のエラーメッセージ表示フラグ
      isErrorExpireMonth: false,
      // 有効期限の年
      expireYear: '',
      // 有効期限(年)のエラーメッセージ表示フラグ
      isErrorExpireYear: false,
      // セキュリティコード
      securityCode: '',
      // セキュリティコードのエラーメッセージ表示フラグ
      isErrorSecurityCode: false,
      // セキュリティコードのヘルプポップアップ表示フラグ
      isShowHelpPopup: false,
    }
  },
  created() {
    // ショップキーでMultipaymentを初期化
    window.Multipayment.init(process.env.VUE_APP_GMO_SHOP_ID)
  },
  methods: {
    /**
     * 前のページに遷移する
     */
    goToBackPage() {
      this.$router.push({name: this.$route.params.prevRouteName})
    },
    /**
     * クレジットカード登録ボタン押下可能か
     * @returns {Boolean} クレジットカード登録ボタン押下可能フラグ
     */
    entryEnabled() {
      // 未入力の場合 登録ボタン非活性
      if (
        this.cardHolder === '' ||
        this.cardNumber === '' ||
        this.expireMonth === '' ||
        this.expireYear === '' ||
        this.securityCode === ''
      ) {
        return false
      }

      return !(
        this.$refs.CardHolderForm.isInvalid ||
        this.$refs.CardNumberForm.isInvalid ||
        this.$refs.ExpireMonthForm.isInvalid ||
        this.$refs.ExpireYearForm.isInvalid ||
        this.$refs.SecurityCodeForm.isInvalid
      )
    },
    /**
     * クレジットカードを登録するボタン押下時の処理
     */
    onClickRegisterCard() {
      this.$store.commit('startLoading')

      // カード情報を作成（Key名はGMOトークン取得IF準拠）
      const cardInfo = {
        cardno: this.cardNumber,
        expire: `${this.expireYear}${this.expireMonth}`,
        securitycode: this.securityCode,
        holdername: this.cardHolder,
      }

      // トークン取得時のオフラインエラーがキャッチ出来ない為、実行前に通信状況をチェック
      if (!navigator.onLine) {
        this.$store.commit('endLoading')
        this.$store.commit('errorModal', 'networkError')
        return
      }

      window.Multipayment.getToken(cardInfo, (result) => {
        // クレジットカード登録処理を実行
        this.registerCreditCard(result)
      })
    },
    /**
     * クレジットカード登録処理
     * @param {Object} result GMOトークン取得のレスポンス
     */
    registerCreditCard(result) {
      if (!result?.tokenObject?.token) {
        // トークンが取得出来なければ共通エラーポップアップを表示
        this.$store.commit('errorModal', 'otherError')
        this.$store.commit('endLoading')
        return
      }

      // 成功時の処理
      const success = () => {
        // 前のページに遷移
        this.goToBackPage()
        this.$store.commit('endLoading')
      }

      // 失敗時の処理
      const failed = (apiResponse) => {
        // クレジットカードエラーモーダル表示判定処理を実行
        this.$store.dispatch('CreditCardsStore/apiFailedAction', apiResponse)
      }

      // クレジットカード登録API実行
      this.$store.dispatch('CreditCardsStore/registerCreditCard', {
        gmoToken: result.tokenObject.token,
        success: success,
        failed: failed,
      })
    },
  },
}
export default RegisterCreditCard
</script>

<style scoped></style>
