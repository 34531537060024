<template>
  <div class="fixed-app flex flex-col">
    <!-- ヘッダー -->
    <Header
      ref="header"
      :isShowPrevious="true"
      headerTitle="お支払い"
      :isShowClose="true"
      @clickPrevious="goToBackPage()"
      @clickClose="clickClose(true)"
    />
    <!-- ボディ -->
    <div class="px-6 mt-5 pb-6 overflow-y-auto scroll-bar-none">
      <!-- 決済説明 -->
      <div class="text-W4 text-[15px] leading-[23px] text-left overflow-hidden">
        以下のお支払い方法で決済します。
        <div class="text-[14px] leading-[22px] ml-1">
          <div class="flex flex-wrap mt-2">
            ・
            <div class="flex-1 pl-2">
              一時お預かり金は料金確定時またはキャンセル時に返却されます。
            </div>
          </div>
          <div class="flex flex-wrap">
            ・
            <div class="flex-1 pl-2">
              サービス利用時に正式な金額に変更されます。
            </div>
          </div>
        </div>
      </div>
      <!-- 一時お預かり金 -->
      <div class="mt-4">
        <div class="text-W7 text-[13px] leading-none text-left mb-2">
          一時お預かり金
        </div>

        <div class="rounded-[8px] border border-gray300 border-solid p-4">
          <div class="flex items-center">
            <div class="mr-2 w-[44px] h-[40px]">
              <img src="@/assets/arrangementIcon/taxi/IconGo.png" alt="" />
            </div>
            <div class="text-W7 text-[18px] text-left">
              タクシー手配サービス
            </div>
          </div>
          <div class="text-W7 text-[22px] mt-5 text-right">￥1,000</div>
        </div>
      </div>

      <!-- ご利用料金 -->
      <div class="mt-4">
        <div class="text-W7 text-[13px] leading-none text-left mb-2">
          ご利用料金
        </div>
        <div
          class="rounded-[8px] border border-gray300 border-solid text-W4 text-[15px] leading-none text-left p-4"
        >
          メーター料金+手配料金など
        </div>
      </div>

      <!-- 支払い方法カード -->
      <div class="mt-4">
        <div class="text-W7 text-[13px] leading-none text-left mb-2">
          お支払い方法
        </div>

        <SelectedPaymentCard
          :priorityCard="priorityCard"
          :hasPriorityCard="hasPriorityCard"
          @change-credit-card="goToCreditCardSelect()"
          @register-credit-card="goToRegisterCreditCard()"
        />
      </div>

      <!-- 電話番号 -->
      <div class="text-left mt-6">
        <div class="text-W7 text-[13px] leading-[13px] mb-2">電話番号</div>
        <TextFormWithValidation
          inputClass="bg-gray200 border-gray200"
          v-model:isError="isTelError"
          :text="userInfo.tel"
          :validationType="['required', 'tel']"
          maxlength="100"
          placeholder="07000123456"
          errorMessage="正しい電話番号を入力してください。"
          @input-text="userInfo.tel = $event"
        />
      </div>

      <!-- メールアドレス -->
      <div class="mt-4 text-left">
        <div class="text-W7 text-[13px] leading-[13px] mb-2">
          メールアドレス
          <span class="text-W4 text-[11px] leading-[11px text-gray">
            （編集不可）
          </span>
        </div>
        <TextForm
          inputClass="bg-gray200 border-gray200"
          :text="userInfo.email"
          :isDisabled="true"
          :isBackButton="false"
        />
      </div>

      <!-- 同意事項 -->
      <div class="mt-4">
        <div class="text-W7 text-[13px] leading-none text-left mb-2">
          同意事項
        </div>
        <div class="rounded-[8px] border border-gray300 border-solid text-left">
          <div class="p-4">
            ご利用にあたって以下にご同意いただくものとします。
            <div class="mt-4 text-[0]">
              <BaseLink
                label="GO 利用規約"
                class="leading-[13px]"
                :url="goTaxiAgreementUrl"
              />
            </div>
            <div class="mt-4 text-[0]">
              <BaseLink
                label="GO プライバシーポリシー"
                class="leading-[13px]"
                :url="goTaxiPrivacyPolicyUrl"
              />
            </div>
          </div>
          <div class="p-4 border-t border-gray300 border-solid">
            <BaseCheckBox v-model="isApplyPolicy" label="上記に同意します。" />
          </div>
        </div>
      </div>

      <div class="mt-6 text-left text-[0]">
        <BaseLink
          label="GO 料金案内"
          class="leading-[13px]"
          :url="goTaxiPricesUrl"
        />
      </div>

      <div class="mt-4 text-left text-[0]">
        <BaseLink
          label="特定商取引法に基づく表記"
          class="leading-[13px]"
          :url="actOnSpecifiedCommercialTransactionsUrl"
        />
      </div>

      <!-- ボタン -->
      <div class="mt-6">
        <TheButton
          text="お支払い方法を確定"
          @click-button="onClickPaymentSubmit()"
          :isDisabled="isPaymentButtonDisabled"
        />
      </div>

      <div class="text-W4 text-[11px] leading-[11px] text-gray text-left mt-2">
        登録メールアドレスにご利用情報をお送りします。
      </div>
    </div>
    <!-- TDS説明モーダル -->
    <TdsExplanationModal
      v-if="isShowTdsExplanationModal"
      @onClickOk="onClickTdsExplanationOk($event)"
    />
    <!-- 決済実行確認モーダル -->
    <Modal
      v-if="isShowPaymentConfirmationModal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-8 pb-6">
        <div class="text-W5 text-[17px] leading-[25.5px]">
          下記のボタンを押すと決済を実行してタクシーを手配します。
        </div>
        <div class="flex justify-center">
          <img
            src="@/assets/arrangementIcon/taxi/MobilityIconTaxi.svg"
            class="w-[200px] h-[96px] mt-6 mb-8"
          />
        </div>
        <TheButton
          class="mb-4"
          buttonType="primary"
          text="タクシーを手配（支払う）"
          @click-button="requestTaxiOrder()"
        />
        <TheButton
          buttonType="secondary"
          text="もどる"
          @click-button="isShowPaymentConfirmationModal = false"
        />
      </div>
    </Modal>
    <!-- 認証ページ遷移失敗モーダル -->
    <Modal
      v-if="isOpenTdsPageFailed"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-8 pb-6">
        <div class="text-W5 text-[17px] leading-[25.5px] mb-2">
          外部ブラウザで認証画面を表示することができませんでした。
        </div>
        <TheButton text="閉じる" @click-button="isOpenTdsPageFailed = false" />
      </div>
    </Modal>

    <!-- タクシー手配中ポップアップ -->
    <Modal
      v-if="isArrangingPopupFlg"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 py-6">
        <img src="@/assets/Wait_Indicator.svg" class="w-16 h-16 m-auto" />
        <div class="font-bold mt-4">タクシーを手配中です...</div>
        <div class="mt-4">
          既に手配が確定していると配車キャンセルできない場合があります。
        </div>
        <TheButton
          text="配車をキャンセル"
          class="the-button-secondary mt-6"
          :isDisabled="disabledCancelFlg"
          @click-button="onClickArrangingCancel()"
        />
      </div>
    </Modal>
    <!-- タクシー手配失敗ポップアップ -->
    <Modal
      v-if="isArrangementFailedPopupFlg"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="py-6 px-5">
        <div class="font-bold mt-3">タクシーの手配ができませんでした。</div>
        <div class="flex justify-center mt-4 items-center">
          <div
            class="w-1/2 py-4 the-button-secondary text-W7 text-[15px] leading-[15px] mr-3"
            @click="clickClose(true)"
          >
            手配をやめる
          </div>
          <TheButton text="再手配" class="w-1/2" @click-button="rearrange()" />
        </div>
      </div>
    </Modal>
    <!-- キャンセル通信中ポップアップ -->
    <Modal
      v-if="isRequestingCancelFlg"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 py-6">
        <img src="@/assets/Wait_Indicator.svg" class="w-16 h-16 m-auto" />
        <div class="text-W5 text-[17px] leading-[25.5px] mt-4">
          しばらくお待ちください...
        </div>
      </div>
    </Modal>
    <!-- タクシー手配キャンセル成功ポップアップ -->
    <Modal
      v-if="isCancelSuccessFlg"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-6 pb-6">
        <div class="font-bold mt-4">配車を取り消しました。</div>
        <TheButton text="OK" class="mt-4" @click-button="clickClose(true)" />
      </div>
    </Modal>
    <!-- タクシー手配キャンセル失敗ポップアップ -->
    <Modal
      v-if="isCancelFailedFlg"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-6 pb-6">
        <div class="font-bold mt-4">配車の取り消しができませんでした。</div>
        <TheButton
          text="OK"
          class="mt-4"
          @click-button="redrawArrangingPopup()"
        />
      </div>
    </Modal>
    <!-- クレジットカード関連エラーポップアップ -->
    <CreditCardErrorModal />
    <!-- GO都合のキャンセル用モーダル -->
    <Modal
      v-if="isOpenPassiveCancelPopup"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 py-6 text-center">
        <div class="text-W5 text-[17px]">
          大変申し訳ございません。
          <br />
          配車がキャンセルされました。
        </div>
        <div class="mt-4 text-W3 text-[15px]">
          お手数おかけしますが、再度タクシーをお呼びください。
        </div>
        <div class="rounded-[4px] mt-6 p-5 bg-gray200 text-left">
          <div class="text-W5 text-[15px]">考えられるキャンセル理由</div>
          <ul class="mt-3 text-W3 text-[13px] text-gray500">
            <li>・道路状況や車両故障で迎車が困難</li>
            <li>・乗務員の体調不良 など</li>
          </ul>
        </div>
        <div class="flex justify-center mt-6 items-center">
          <div
            class="w-1/2 py-4 the-button-secondary text-W7 text-[15px] leading-[15px] mr-3"
            @click="clickClose(true)"
          >
            手配をやめる
          </div>
          <TheButton class="w-1/2" text="再手配" @click-button="rearrange()" />
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import Header from '@/components/organisms/Header.vue'
import SelectedPaymentCard from '@/components/molecules/SelectedPaymentCard.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import Modal from '@/components/Modal.vue'
import CreditCardErrorModal from '@/modals/CreditCardErrorModal.vue'
import ArrangementUtil from '@/mixins/arrangementUtil.js'
import Util from '@/mixins/util.js'
import NativeUtil from '@/mixins/nativeUtil.js'
import BaseLink from '@/components/atoms/BaseLinkV2.vue'
import BaseCheckBox from '@/components/atoms/BaseCheckBoxV2.vue'
import TextForm from '@/components/TextFormV2.vue'
import TextFormWithValidation from '@/components/molecules/TextFormWithValidationV2.vue'
import deepcopy from 'deepcopy'
import TdsExplanationModal from '@/modals/TdsExplanationModal.vue'
import {
  getIsSkipTdsExplanationFromLocalStorage,
  setIsSkipTdsExplanationFromLocalStorage,
} from '@/utils/localStorageUtil'

// タクシー配車キャンセル理由（ユーザーキャンセル）
const CANCEL_REASON_USER = 'user_cancel'
// タクシー配車キャンセル理由（ユーザー退会済み）
const CANCEL_REASON_WITHDRAWAL = 'user_withdrawal'

const TaxiReservationPaymentConfirm = {
  name: 'TaxiReservationPaymentConfirm',
  components: {
    Header,
    SelectedPaymentCard,
    TheButton,
    Modal,
    CreditCardErrorModal,
    BaseLink,
    TextForm,
    TextFormWithValidation,
    BaseCheckBox,
    TdsExplanationModal,
  },
  mixins: [ArrangementUtil, Util, NativeUtil],
  data() {
    return {
      isArrangingPopupFlg: false, // 手配中ポップアップフラグ
      isShowTdsExplanationModal: false, // 3DS認証説明モーダル表示フラグ
      isShowPaymentConfirmationModal: false, // 決済確認モーダル表示フラグ
      disabledCancelFlg: true, // 配車キャンセル非活性フラグ
      isArrangementFailedPopupFlg: false, // 手配失敗ポップアップフラグ
      isRequestingCancelFlg: false, // キャンセル通信中フラグ
      isCancelSuccessFlg: false, // 手配キャンセル成功フラグ
      isCancelFailedFlg: false, // 手配キャンセル失敗フラグ
      isOpenTdsPageFailed: false, // 認証ページ遷移失敗モーダル表示フラグ
      priorityCard: {}, // 優先使用されるクレジットカード情報
      userInfo: {}, // タクシーの利用者情報
      isTelError: false, // 電話番号エラー
      isBackPageFlg: false, // 前の画面に戻るフラグ（入力情報を初期化するフラグ）
      isApplyPolicy: false, // 利用規約同意状況
      goTaxiAgreementUrl: process.env.VUE_APP_GO_TAXI_AGREEMENT_URL, // GO 利用規約URL
      goTaxiPrivacyPolicyUrl: process.env.VUE_APP_GO_TAXI_PRIVACY_POLICY_URL, // GO プライバシーポリシーURL
      goTaxiPricesUrl: process.env.VUE_APP_GO_TAXI_PRICES_URL, // GO 料金案内URL
      actOnSpecifiedCommercialTransactionsUrl:
        process.env.VUE_APP_NAMO_ACT_ON_SPECIFIED_COMMERCIAL_TRANSACTIONS_URL, // 特定商取引法に基づく表記 URL
      isOpenPassiveCancelPopup: false, // 受動的キャンセルモーダル表示フラグ
      creditOrderId: '', // 決済ID
    }
  },
  computed: {
    /**
     * ルート情報を返す
     */
    routeInfo() {
      return this.$store.state.MobilityReservationStore.taxi.routeInfo
    },
    /**
     * 遷移元画面情報
     */
    screenTransitionSource() {
      return this.$store.state.MobilityReservationStore.screenTransitionSource
    },
    /**
     * 遷移元フッターID
     */
    screenTransitionFooterId() {
      return this.screenTransitionSource.footerId
    },
    /**
     * タクシー配車ステータスを取得
     */
    taxiStatus() {
      return this.$store.state.MobilityReservationStore.taxi.reservationStatus
    },
    /**
     * タクシー配車情報
     */
    taxiVehicleInfo() {
      return this.$store.state.MobilityReservationStore.taxi.vehicleInfo
    },
    /**
     * お支払い方法を保持しているか
     * @returns {Boolean} お支払い方法保持しているか
     */
    hasPriorityCard() {
      return !this.isEmpty(this.priorityCard)
    },
    /**
     * タクシー手配（支払う）ボタン非活性
     */
    isPaymentButtonDisabled() {
      // 入力情報にエラがーある場合は非活性
      if (this.isTelError) {
        return true
      }
      // 未同意の場合は非活性
      if (!this.isApplyPolicy) {
        return true
      }
      // お支払い方法を保持していなければ非活性
      if (!this.hasPriorityCard) {
        return true
      }
      return false
    },
  },
  watch: {
    /**
     * タクシー手配ステータスによって、アクションが変わる
     */
    taxiStatus: function () {
      this.switchDisplayByTaxiReservationStatus()
    },
  },
  created() {
    // 優先するカード情報をStorageから取得しStoreに保持
    this.$store.dispatch('CreditCardsStore/loadLocalPriorityCardSeq')
    // 使用対象のクレジットカード情報を取得
    this.priorityCard = this.$store.getters['CreditCardsStore/selectedCard']

    // タクシーの利用者情報の初期値を設定
    this.setUpUserInfo()
    // webViewの場合はFG復帰時の生体認証失敗処理を定義
    if (this.isWebView()) {
      this.defineResumeBioAuthFailedMethod()
    }
  },
  beforeUnmount() {
    // webViewの場合は生体認証失敗処理定義をwindowから削除
    if (this.isWebView()) {
      delete window.resumeBioAuthFailed
    }
  },
  unmounted() {
    if (this.isBackPageFlg) {
      // ストアのタクシー利用者情報を初期化
      this.$store.commit('MobilityReservationStore/updateTaxiUserInfo', {})
    }
  },
  methods: {
    /**
     * 「お支払い方法を確定」押下時処理
     * 3DS認証に対する説明モーダルを表示する。
     * ※「次回以降表示しない」を有効の場合、上記をSKIPし3DS本人認証APIを実行する
     */
    onClickPaymentSubmit() {
      const isSkip = getIsSkipTdsExplanationFromLocalStorage().taxi
      if (isSkip) {
        // スキップを有効化している場合は、3DS本人認証APIを実行
        this.executeTdsChallenge()
      } else {
        // スキップを有効化していない場合は、3DSに対する説明モーダルを表示
        this.isShowTdsExplanationModal = true
      }
    },
    /**
     *  3DS認証に対する説明モーダルで「OK」押下時処理
     * 「次回以降表示しない」の選択情報を保持し、3DS本人認証APIを実行する
     * @param {Boolean} isNoDisplayNextTime 「次回以降表示しない」の有効可否
     */
    onClickTdsExplanationOk(isNoDisplayNextTime) {
      // 「次回以降確認しない」の情報をストレージ保持
      setIsSkipTdsExplanationFromLocalStorage('taxi', isNoDisplayNextTime)
      // 本人認証実施
      this.executeTdsChallenge()
    },
    /**
     * 本人認証APIを実行
     * ・成功時：認証ページに外部リンク遷移を行い、表示モーダルを切り替え
     * ・失敗時(failed)：返却された情報に応じてエラーモーダル表示
     * ・失敗時(error)：共通エラーモーダル表示
     */
    executeTdsChallenge() {
      this.$store.commit('startLoading')
      const cardSeq = this.priorityCard.cardSeq
      const amount = 1000
      // API実行後の共通処理
      const finalAction = () => {
        this.$store.commit('endLoading')
        this.isShowTdsExplanationModal = false
      }

      // 本人認証API実行
      this.$store.dispatch('CreditCardsStore/registerPaymentTransaction', {
        cardSeq: cardSeq,
        amount: amount,
        success: async (result) => {
          // 決済IDを保持
          this.creditOrderId = result.creditOrderId
          try {
            // 返却されたURLに外部ブラウザで表示
            await this.openExternalUrl(result.redirectUrl)
            // 同時にモーダルの表示切り替え
            this.isShowPaymentConfirmationModal = true
          } catch (e) {
            // 外部ブラウザ表示失敗時のモーダルを表示
            this.isOpenTdsPageFailed = true
          }
          finalAction()
        },
        failed: (response) => {
          // 返却されたエラー内容を解析してエラーモーダル表示する。
          try {
            this.$store.dispatch('CreditCardsStore/apiFailedAction', response)
          } catch (e) {
            this.isArrangementFailedPopupFlg = true
          }
          finalAction()
        },
        error: (e) => {
          finalAction()
          throw e
        },
      })
    },
    /**
     * ヘッダーの戻るボタン押下処理
     */
    goToBackPage() {
      // タクシー利用者情報の初期化フラグを立てる
      this.isBackPageFlg = true
      this.$router.push({
        name: this.$config.DISPLAY_TAXI_RESERVATION_CONFIRM,
      })
    },
    /**
     * タクシー手配準備処理
     * LocalStorageやStoreに対し、必要情報を設定
     */
    setupTaxiOrder() {
      // ローカルストレージに使用するクレジットカード連番を格納
      this.$store.dispatch(
        'CreditCardsStore/updateLocalPriorityCardSeq',
        this.priorityCard.cardSeq
      )

      // タクシー利用者情報に入力内容を保存
      this.$store.commit('MobilityReservationStore/updateTaxiUserInfo', {
        tel: this.userInfo.tel,
        email: this.userInfo.email,
      })
      // メッセージ既読履歴数を初期化
      this.setLocalStorage(this.$config.MESSAGE_LOG_LENGTH, null)
    },
    /**
     * タクシー配車依頼実行処理
     */
    requestTaxiOrder() {
      // タクシー手配に向けて準備
      this.setupTaxiOrder()

      // 決済実行確認モーダルを非表示
      this.isShowPaymentConfirmationModal = false
      // 手配中モーダル表示処理
      this.isArrangingPopupFlg = true
      // キャンセルボタンは非活性
      this.disabledCancelFlg = true

      // タクシー手配実行
      this.$store.dispatch('MobilityReservationStore/taxiRequestOrders', {
        creditOrderId: this.creditOrderId,
        success: () => {
          // キャンセルボタン活性化
          this.disabledCancelFlg = false
          // ポーリング開始
          this.$store.dispatch('MobilityReservationStore/startTaxiPolling')
        },
        failed: (apiResponse) => {
          try {
            // クレジットカードエラーモーダル表示判定処理を実行
            this.$store.dispatch(
              'CreditCardsStore/apiFailedAction',
              apiResponse
            )
          } catch (e) {
            // 手配失敗モーダルを表示
            this.isArrangementFailedPopupFlg = true
          } finally {
            // 手配中モーダル非表示処理
            this.isArrangingPopupFlg = false
          }
        },
        error: (e) => {
          // 手配中モーダル非表示処理
          this.isArrangingPopupFlg = false
          throw e
        },
      })
    },
    /**
     * 配車キャンセル処理
     */
    onClickArrangingCancel() {
      const taxiReservationStatus = this.$config.TAXI_RESERVATION_STATUS
      // 配車が既にキャンセルとなっている場合は処理を実行しない
      if (this.taxiStatus !== taxiReservationStatus.CANCELED) {
        // キャンセル待ちポップアップ展開
        this.isRequestingCancelFlg = true
        this.isArrangingPopupFlg = false

        // キャンセル失敗用ポップアップ画面を展開
        const failed = () => {
          this.isRequestingCancelFlg = false
          // 配車が既にキャンセルとなっている場合は表示しない
          if (this.taxiStatus !== taxiReservationStatus.CANCELED) {
            this.isCancelFailedFlg = true
          }
        }
        // 手配中モーダルの上にエラーに対応した汎用モーダルを表示
        const error = (e) => {
          this.isRequestingCancelFlg = false
          this.isArrangingPopupFlg = true
          throw e
        }
        // タクシー手配キャンセル実行
        this.$store.dispatch('MobilityReservationStore/cancelTaxiReservation', {
          failed: failed,
          error: error,
        })
      }
    },
    /**
     * 配車ステータスによって画面表示を切り替える
     */
    switchDisplayByTaxiReservationStatus() {
      const taxiReservationStatus = this.$config.TAXI_RESERVATION_STATUS

      // タクシーが見つからなかった場合
      if (this.taxiStatus === taxiReservationStatus.DISPATCH_FAILED) {
        this.isArrangingPopupFlg = false
        this.isRequestingCancelFlg = false
        this.isArrangementFailedPopupFlg = true
        return
      }

      // キャンセルリクエスト中はスキップ
      if (!this.isRequestingCancelFlg) {
        // タクシーが見つかった〜目的地到着の場合
        if (
          this.taxiStatus === taxiReservationStatus.DISPATCHING ||
          this.taxiStatus === taxiReservationStatus.PICK_UP_ARRIVED ||
          this.taxiStatus === taxiReservationStatus.ON_DELIVER ||
          this.taxiStatus === taxiReservationStatus.DROP_OFF ||
          this.taxiStatus === taxiReservationStatus.SUCCEED
        ) {
          // 手配中ポップアップを閉じる
          this.isArrangingPopupFlg = false
          // タクシー手配完了画面に遷移
          this.dispatchingAction()
          return
        }
      }

      // キャンセルが完了した場合
      if (this.taxiStatus === taxiReservationStatus.CANCELED) {
        // しばらくお待ちくださいポップアップを非表示
        this.isRequestingCancelFlg = false
        // キャンセル手配中モーダルを非表示
        this.isArrangingPopupFlg = false
        if (
          this.taxiVehicleInfo.cancelReason === null ||
          this.taxiVehicleInfo.cancelReason === CANCEL_REASON_USER
        ) {
          // タクシー配車キャンセル理由が取得できない、またはユーザー起因の場合、配車を取り消しましたポップアップを表示
          this.isCancelSuccessFlg = true
        } else if (
          this.taxiVehicleInfo.cancelReason === CANCEL_REASON_WITHDRAWAL
        ) {
          // ユーザー退会済みの場合、汎用エラーモーダル表示
          throw new Error()
        } else {
          // 上記以外の場合、GO都合のキャンセル用モーダルを表示
          this.isOpenPassiveCancelPopup = true
        }
      }
    },
    /**
     * タクシーが乗車場所に向かっている場合の処理
     */
    dispatchingAction() {
      // 遷移元フッターIDが手配IDの場合は、遷移元を検索TOP画面へ変更
      if (
        this.screenTransitionFooterId === this.$config.FOOTER_ID_ARRANGEMENT
      ) {
        this.$store.commit(
          'MobilityReservationStore/updateScreenTransitionSource',
          {
            displayName: this.$config.DISPLAY_SEARCH_TOP,
            footerId: this.$config.FOOTER_ID_SPOT,
          }
        )
      }

      // お客様のタクシー画面に遷移
      this.$router.push({
        name: this.$config.DISPLAY_TAXI_RESERVATION_COMPLETED,
      })
    },
    /**
     * タクシー手配中ポップアップの再描画
     */
    redrawArrangingPopup() {
      const taxiReservationStatus = this.$config.TAXI_RESERVATION_STATUS
      // タクシー手配キャンセル失敗ポップアップを非表示
      this.isCancelFailedFlg = false
      if (this.taxiStatus !== taxiReservationStatus.CANCELED) {
        // 既にキャンセル済みの場合は手配中ポップアップを再描画しない
        this.isArrangingPopupFlg = true
      }
      this.switchDisplayByTaxiReservationStatus()
    },
    /**
     * タクシー再手配
     */
    rearrange() {
      this.isArrangementFailedPopupFlg = false
      this.isOpenPassiveCancelPopup = false
      this.executeTdsChallenge()
    },
    /**
     * クレジットカード選択画面へ遷移する
     */
    goToCreditCardSelect() {
      // 遷移元のパスをstoreに登録
      this.$store.commit('CreditCardsStore/updateScreenTransitionSource', {
        displayName: this.$route.name,
      })
      this.$router.push({name: this.$config.DISPLAY_PAYMENT_METHOD_SELECT})
    },
    /**
     * クレジットカード登録画面へ遷移する
     */
    goToRegisterCreditCard() {
      // 遷移元のパスをstoreに登録
      this.$store.commit('CreditCardsStore/updateScreenTransitionSource', {
        displayName: this.$route.name,
      })
      this.$router.push({name: this.$config.DISPLAY_REGISTER_CREDIT_CARD})
    },
    /**
     * タクシーの利用者情報の初期値を設定する
     */
    setUpUserInfo() {
      // ストアのタクシー利用者情報の値を取得
      const taxiUserInfo = deepcopy(
        this.$store.state.MobilityReservationStore.taxi.userInfo
      )
      // タクシーの利用者情報がなければアプリのユーザー情報の値を初期値としてセット
      this.userInfo = this.isEmpty(taxiUserInfo)
        ? deepcopy(this.$store.state.user.info)
        : taxiUserInfo
    },
    /**
     * FG復帰時の生体認証失敗処理をwindowに定義
     */
    defineResumeBioAuthFailedMethod() {
      window.resumeBioAuthFailed = () => {
        this.clickClose(true)
      }
    },
  },
}
export default TaxiReservationPaymentConfirm
</script>
<style scoped></style>
