<template>
  <Modal
    :isModalCenter="true"
    :isShowCloseButton="false"
    modalPaddingX="20px"
    modalPaddingBottom="0px"
  >
    <div class="px-5 py-5 pt-9">
      <div class="text-W5 text-[17px] leading-[25.5px]">
        3Dセキュア認証サイトに遷移します。画面の表示に従って操作をしてください。
      </div>
      <BaseCheckBox
        class="my-[25px]"
        v-model="isNoDisplayNextTime"
        label="次回以降表示しない"
      />
      <TheButton
        text="OK"
        @click-button="$emit('onClickOk', isNoDisplayNextTime)"
      />
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import BaseCheckBox from '@/components/atoms/BaseCheckBoxV2.vue'

const TdsExplanationModal = {
  name: 'TdsExplanationModal',
  components: {Modal, TheButton, BaseCheckBox},
  emits: ['onClickOk'],
  data() {
    return {
      isNoDisplayNextTime: false, // 本モーダルを次回以降SKIPするかどうか
    }
  },
}
export default TdsExplanationModal
</script>

<style scoped></style>
