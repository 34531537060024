<template>
  <div class="text-W7 text-left text-[15px] leading-[26px] px-1.5 pb-1.5">
    どこからどこへ行きますか？
    <!-- 乗車地選択 -->
    <BaseBox class="mt-1.5">
      <template v-slot:explanation>
        <div
          class="flex flex-row items-center bg-gray200 rounded-[4px] py-[6px] px-3"
          @click="$emit('pick-up-config-start')"
        >
          <div class="flex flex-1 flex-col grid">
            <div class="flex flex-row items-center">
              <img
                src="@/assets/arrangementIcon/taxi/IconPickUpPin.svg"
                class="h-[13px] w-2.5"
              />
              <div class="ml-1.5 text-W4 text-xs leading-[18px] text-gray500">
                乗車地
              </div>
            </div>
            <div
              class="mt-[2px] text-[12px] leading-[18px] h-[18px] truncate"
              :class="addressNameFieldClass"
            >
              {{ addressNameFieldText }}
            </div>
          </div>
          <div class="flex flex-row items-center">
            <div class="text-W4 text-xs leading-[18px] text-blue">検索</div>
            <img src="@/assets/Icon_Right_blue.svg" class="h-3 w-3" />
          </div>
        </div>
      </template>
    </BaseBox>
    <!-- セクション区切りアイコン -->
    <img src="@/assets/Icon_down_gray.svg" class="h-2.5 w-2.5 m-auto my-1" />
    <!-- 目的地選択 -->
    <BaseBox>
      <template v-slot:explanation>
        <div
          class="flex flex-row items-center bg-blue200 rounded-[4px] py-[6px] px-3"
          @click="$emit('drop-off-config-start')"
        >
          <div class="flex flex-1 flex-col grid">
            <div class="flex flex-row items-center">
              <img
                src="@/assets/arrangementIcon/taxi/IconDropOffPin.svg"
                class="h-3 w-3"
              />
              <div class="ml-1.5 text-W4 text-xs leading-[18px] text-gray500">
                目的地
              </div>
            </div>
            <div
              class="mt-[2px] text-W6 text-[12px] leading-[18px] h-[18px] truncate"
            >
              {{ dropOffAddress }}
            </div>
          </div>
          <div class="flex flex-row items-center">
            <div class="text-W4 text-xs leading-[18px] text-blue">設定</div>
            <img src="@/assets/Icon_Right_blue.svg" class="h-3 w-3" />
          </div>
        </div>
      </template>
    </BaseBox>
    <!-- 次へすすむボタン -->
    <TheButton
      text="次へすすむ"
      class="mt-4"
      :isDisabled="isButtonDisabled"
      @click-button="$emit('click-next')"
    />
  </div>
</template>
<script>
import BaseBox from '@/components/atoms/BaseBox.vue'
import BaseCard from '@/components/atoms/BaseCard.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import Util from '@/mixins/util.js'

/**
 * どこからどこへ行きますか?コンポーネント
 */
const TaxiReservationWhereToGo = {
  name: 'TaxiReservationWhereToGo',
  mixins: [Util],
  emits: ['pick-up-config-start', 'drop-off-config-start', 'click-next'],
  components: {
    BaseBox,
    BaseCard,
    TheButton,
  },
  props: {
    // タクシー情報
    routeInfo: {
      type: Object,
      require: true,
    },
    // ボタン非活性フラグ
    isButtonDisabled: {
      type: Boolean,
      default: false,
    },
    // 乗車地の住所名表示フラグ
    isShowPickupAddressNameText: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    /**
     * 乗車地の住所
     */
    pickUp() {
      return this.routeInfo.pickUp
    },
    /**
     * 目的地の住所
     */
    dropOff() {
      return this.routeInfo.dropOff
    },
    /**
     * 目的地の初期値
     */
    dropOffAddress() {
      return !this.isNull(this.dropOff.address)
        ? this.dropOff.address
        : '指定しない'
    },
    /**
     * 住所が設定されていないかどうかを返却
     */
    isNoSettingPickupAddress() {
      return this.pickUp.address == ''
    },
    /**
     * 住所名表示エリアに設定する追加クラスを返却
     */
    addressNameFieldClass() {
      return this.isNoSettingPickupAddress ? 'text-W2 text-gray' : 'text-W6'
    },
    /**
     * 住所名表示エリアに表示するテキストを返却
     */
    addressNameFieldText() {
      if (!this.isShowPickupAddressNameText) {
        return ''
      }
      return this.isNoSettingPickupAddress
        ? '住所が取得できません'
        : this.pickUp.address
    },
  },
}
export default TaxiReservationWhereToGo
</script>
<style scoped></style>
