import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import config from '@/const'
import i18n from './i18n'
import '@/assets/style/tailwind.css'
import auth from '@/auth/createAuth'
import {init as AuthorizationCommitterInit} from '@/auth/AuthorizationCommitter'

import {constant} from '@/const/const.js'
import * as log from 'loglevel'
const logLevel =
  process.env.NODE_ENV === 'development' ? log.levels.DEBUG : log.levels.SILENT
log.setLevel(logLevel, false)

// NRにアプリバージョンを設定
if(window.newrelic){
  window.newrelic.setApplicationVersion(process.env.VUE_APP_PROJECT_VERSION)
}

// Auth0初期化
AuthorizationCommitterInit()

const app = createApp(App)
  .use(store)
  .use(router)
  .use(config.install)
  .use(i18n)
  .use(auth)

/**
 * 画面に指定されたエラータイプのメッセージを表示する
 * @param {*} errorType App.vueのERROR_TYPESオブジェクトのkey
 */
const showError = (errorType) => {
  // ローディング画像を閉じる
  store.commit('endLoading')

  // エラーメッセージを表示
  store.commit('errorModal', errorType)
}
/**
 * エラーハンドラで例外をキャッチ
 * errorイベント、unhandledrejectionイベント以外をキャプチャする
 * @param {*} err
 * @param {*} vm
 * @param {*} info
 */
app.config.errorHandler = (err, vm, info) => {
  log.debug(`Captured in Vue.config.errorHandler: ${info}`, err)
  // 閉局中エラー判定
  if (
    err.response?.data?.statusCode ===
    constant.API_STATUS_CODE_SERVICE_UNAVAILABLE
  ) {
    // メンテナンス中モーダルを設定
    return showMaintenanceErrorModal(err.response)
  }
  showError('otherError')
}
/**
 * エラーイベント処理(error)
 * Javascriptコード内で発生したcatchされていないエラーをキャプチャする
 */
window.addEventListener('error', (event) => {
  log.debug('Captured in error EventListener', event.error)
  if (event?.error?.message?.includes('invalidAccessToken')) {
    showError('invalidAccessToken')
  } else {
    showError('otherError')
  }
})
/**
 * エラーイベント処理(unhandledrejection)
 * Promiseの処理でcatchされていないRejectをキャプチャする
 */
window.addEventListener('unhandledrejection', (event) => {
  log.debug('Captured in unhandledrejection EventListener', event.reason)

  // 閉局中エラー判定
  if (
    event.reason.response?.data?.statusCode ===
    constant.API_STATUS_CODE_SERVICE_UNAVAILABLE
  ) {
    // メンテナンス中モーダルを設定
    return showMaintenanceErrorModal(event.reason.response)
  }

  const errorMessage = event?.reason?.message
  if (errorMessage) {
    if (errorMessage.includes(constant.AUTH0_NO_EMAIL_VERIFIED_ERROR)) {
      // メール未認証の場合は何も行わない
      return
    }
    if (errorMessage.includes('networkError')) {
      showError('networkError')
      return
    }
    if (errorMessage.includes('invalidAccessToken')) {
      showError('invalidAccessToken')
      return
    }
  }
  showError('otherError')
})

/**
 * メンテナンス中モーダルを設定、表示する
 * @param {*} response
 */
function showMaintenanceErrorModal(response) {
  // ローディング画像を閉じる
  store.commit('endLoading')

  const responseParam = response.data.body?.param
  // メンテンスの情報を取得
  const closed = responseParam?.closed
  // NAMOのメンテンスエラーか判定
  const isNamoMaintenanceError =
    responseParam?.info === constant.API_MAINTENANCE_ERROR_MESSAGE
  const closedDataList = isNamoMaintenanceError && closed ? closed : []
  // メンテナンス終了時刻を更新
  store.commit('updateClosedDataList', closedDataList)
  // 閉局中エラー表示用フラグを更新
  store.commit('updateIsShowClosedErrorModal', true)
}

app.mount('#app')
